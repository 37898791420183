/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.profile-screen {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__body {
        flex: 1;
        position: relative;
        display: flex;
        width: 100%;
        padding: 0;

        &__left {
            position: relative;
            flex: 1;
            height: 100%;
            width: calc(100vw - #{$right-drawer-width});
            display: flex;
            flex-direction: column;


            &__header {
                position: relative;
                width: 100%;
                height: 185px;
                background-color: #082422;
                background-position: bottom right;
                background-repeat: no-repeat;

                .image {
                    height: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                .fetching {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 80px;
                    left: 50%;
                    z-index: $zindex-loader-svg;

                    > span {
                        display: inline-block;
                        width: 40px;
                        height: 40px;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &--avatar {
                        top: 165px;
                        left: 50px;
                        z-index: $zindex-loader-svg;

                        @include screen-below('sm') {
                            left: 20px;
                        }
                    }
                }

                .cover {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;

                    &__image {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }

                    img {
                        display: block;
                        height: 99999px;
                        max-height: 100%;
                        max-width: 100%;
                        margin: auto auto;
                        object-fit: cover;
                    }

                    &__tooltip {
                        color: white;
                        text-align: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(#000000, 0.5);
                        z-index: 0;
                        opacity: 0;
                        transition: opacity 0.25ms ease-in-out;

                        padding: 70px;
                        svg {
                            width: 30px;
                            height: 30px;
                            fill: white;
                        }

                        @media (max-width: $breakpoint-xs) {
                            padding-top: 50px;
                        }
                    }

                    &--editable {
                        &:hover {
                            cursor: pointer;

                            .cover__tooltip {
                                opacity: 1;
                            }
                        }
                    }
                }

                .buttons {
                    display: flex;
                    position: fixed;
                    top: 65px;
                    right: 400px;
                    z-index: 1;
                    height: 0;
                    background-color: transparent;
                    transition: background-color .3s, height .3s ease-out;

                    @media (max-width: $breakpoint-md) {
                        right: 3px;
                    }

                    button {
                        margin-left: 5px;
                        width: auto;
                    }

                    &--scroll {
                        justify-content: flex-end;
                        align-items: end;
                        width: 100%;
                        height: 60px;
                        top: 50px;
                        right: 385px;
                        padding: 10px 15px;
                        background-color: #fff;
                        box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.5);

                        @media (max-width: 1025px) {
                            right: 0;
                        }
                    }

                    &__profile {
                        display: flex;
                        position: fixed;
                        top: 65px;
                        right: 15px;
                        z-index: 1;
                        height: 0;
                        background-color: transparent;
                        transition: background-color .3s, height .3s ease-out;

                        @media (max-width: $breakpoint-md) {
                            right: 8px;

                            button {
                                margin-left: 3px;
                                width: auto;
                            }
                        }

                        &--scroll {
                            justify-content: flex-end;
                            align-items: end;
                            width: 100%;
                            height: 60px;
                            top: 50px;
                            right: 0;
                            padding: 10px 15px;
                            background-color: #fff;
                            box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.5);
                        }
                    }
                }

                .avatar {
                    position: absolute;
                    bottom: -30px;
                    left: 40px;

                    @include screen-below('sm') {
                        left: 20px;
                    }

                    @media (max-width: $breakpoint-xs) {
                        z-index: 0;
                    }
                }

                .gallery-btn {
                    display: none;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    border-radius: 0;
                    background-color: #001608;
                    z-index: 0;

                    @include screen-below(lg) {
                        display: block;
                    }
                }
            }

            &__content {
                flex: 1;
                padding: 40px 0;
                width: 100%;

                &__profile {
                    flex: 1;
                    padding: 40px 0;
                    width: 65%;
                    margin: 0 auto;
                }

                h1 {
                    font-family: $font-maven-bold;
                    font-size: 18px;
                    color: $color-green-001609;
                }

                h2 {
                    font-family: $font-maven-medium;
                    font-size: 16px;
                    color: $color-primary;
                }

                h3.title {
                    margin-top: 30px;
                }

                h4 {
                    margin: 20px 0;
                    font-family: $font-aachen-bt-bold;
                    font-size: 22px;
                    color: $color-primary;
                    font-weight: lighter;
                }

                p {
                    font-family: $font-maven-regular;
                    font-size: 14px;
                    color: $color-green-001609;

                    &.placeholder {
                        opacity: 0.35;
                    }
                }

                .profile-top {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 0 40px;

                    @include screen-between(sm, md) {
                        flex-direction: column;
                    }

                    @include screen-below('sm') {
                        padding: 0 20px;
                    }

                    .form-field {
                        margin: 15px 0;
                    }

                    .athlete-info {
                        width: 100%;
                        margin-bottom: 30px;

                        h1 {
                            width: 100%;
                            margin: 0;
                        }

                        h2 {
                            width: 100%;
                            margin: 0;
                        }

                        .form-field {
                            width: 100%;
                        }

                        @include screen-below(md) {
                            .form-field {
                                width: 100%;
                            }
                        }

                        .react-select-container {
                            width: 100%;
                        }

                        .icn {
                            margin-top: 1.5%;
                        }

                        .with-icon {
                            .form-field {
                                margin: 15px 0;
                            }
                        }
                    }

                    .stats-container {
                        width: 100%;
                    }

                    &__stats {
                        display: flex;

                        ul {
                            padding: 0 20px;

                            @include screen-between(sm, md) {
                                padding: 0;
                                gap: 13px;
                            }
                        }
                    }

                    &__left {
                        display: flex;
                        flex-wrap: wrap;
                        flex: 1;
                    }

                    &__right {
                        display: flex;

                        &__content {
                            align-items: center;
                            display: flex;

                            @include screen-below(md) {
                                flex-direction: column-reverse;
                                align-items: flex-start;
                                width: 100%;

                                button {
                                    margin-left: 0;
                                    width: 100%;
                                }
                            }

                            .icn {
                                margin-left: 20px;
                                display: flex;

                                @include screen-below(md) {
                                    margin-left: 0;
                                }
                            }

                            &__proposal {
                                @include screen-below(md) {
                                    padding: 25px 30px;
                                }
                            }
                        }
                    }
                }

                .profile-info {
                    padding: 0 40px;

                    @include screen-below('sm') {
                        padding: 0 20px;
                    }

                    &__club {
                        display: inline-flex;
                        align-items: center;
                        margin: 10px 20px 10px 0;
                        background-color: $color-primary;
                        border-radius: 0 20px 20px 0;

                        span {
                            padding: 0 25px 0 20px;
                        }

                        &__logo {
                            display: flex;
                            align-items: center;
                            width: 29px;
                            height: 36px;
                            background-color: white;
                            border-radius: 0 20px 20px 0;
                        }
                    }
                }

                .athlete-stats {
                    width: 100%;

                    > div {
                        margin: 0 0 30px 0;
                        font-family: $font-maven-regular;
                        font-size: 14px;
                        color: $color-green-001609;
                        white-space: nowrap;
                    }
                }

                .profile-stats {
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: flex;
                        align-items: center;
                        margin: 0 15px 30px 0;
                        font-family: $font-maven-regular;
                        font-size: 14px;
                        color: $color-green-001609;
                        white-space: nowrap;

                        .icn {
                            display: flex;
                            justify-content: center;
                            width: 30px;

                            img {
                                margin: 0 5px 0 0;
                            }

                            &--phone {
                                align-items: center;

                                svg {
                                    height: 20px;
                                    width: auto;

                                    path {
                                        fill: $color-primary;
                                    }
                                }
                            }
                        }

                        .form-field {
                            flex: 1;
                            margin: 0;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        @include screen-below(md) {
                            margin-right: 0;
                            width: 100%;
                        }

                        .react-select-container {
                            width: 180px;
                        }

                        @media (max-width: $breakpoint-md) {
                            .react-select-container {
                                width: 100%;
                            }
                        }
                    }

                    &__sportest-badge {
                        display: flex;
                        justify-content: center;
                        position: relative;
                        color: white;

                        &__title {
                            position: absolute;
                            text-transform: uppercase;
                            font-family: $font-roboto-medium;
                            font-size: .85rem;
                        }

                        &__result {
                            position: absolute;
                            align-self: center;
                            margin-top: -.5rem;
                            font-size: 1.4rem;
                            font-family: $font-roboto-medium;

                        }
                    }

                    &--athlete {
                        @include screen-below(sm) {
                            flex-direction: column;
                        }

                        li {
                            @include screen-below(md) {
                                width: 40%;
                                white-space: nowrap;
                            }
                        }
                    }
                }

                .with-icon {
                    display: flex;
                    align-items: center;

                    .icn {
                        display: flex;
                        justify-content: center;
                        width: 30px;

                        img {
                            margin: 0 5px 0 0;
                        }

                        &--phone {
                            align-items: center;

                            svg {
                                height: 20px;
                                width: auto;

                                path {
                                    fill: $color-primary;
                                }
                            }
                        }
                    }

                    .form-field {
                        flex: 1;
                        margin: 0;
                    }
                }

                .profile-sponsors {
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        width: 50px;
                        height: 50px;
                        margin: 0 20px 20px 0;
                        border-radius: 25px;
                        background-color: rgba($color-primary, 0.1);
                    }
                }

                .content-box {
                    padding-bottom: 30px;
                    overflow: auto;

                    &--actions {
                        position: relative;
                    }

                    &__actions {
                        position: absolute;
                        width: min-content;
                        display: flex;
                        align-items: center;
                        right: 0;
                        top: 20px;

                        &__plus {
                            width: 39px;
                            height: 39px;
                            padding: 0;
                            margin-top: -15px;

                            > span {
                                font-size: 48px;
                                margin-top: -5px;
                                font-family: $font-maven-medium;
                            }
                        }

                        &__nav {
                            margin-bottom: 10px;

                            @include screen-below(md) {
                                display: none;
                            }
                        }
                    }

                    &__item {
                        display: flex;
                        align-items: center;

                        &--sponsored {
                            flex-wrap: wrap;

                            .avatar {
                                cursor: pointer;

                                &:not(:last-of-type) {
                                    margin-right: 15px;
                                }
                            }
                        }

                        .icn {
                            display: flex;
                            margin-right: 6px;

                            &--green {
                                align-items: center;
                                height: 24px;
                                width: 24px;

                                svg {
                                    path {
                                        fill: $color-primary;
                                    }
                                }
                            }

                            &--www {
                                width: 18px;
                                margin: 0 9px 0 3px;
                            }
                        }

                        > span,
                        > a {
                            font-size: 14px;
                            font-family: $font-maven-regular;
                            color: $color-green-001609;
                        }

                        > a {
                            color: $color-primary;
                        }

                        &--centered {
                            align-items: center;
                        }

                        .form-field {
                            flex-grow: 1;
                        }

                        .modal {
                            .form-field {
                                flex-grow: 0;
                            }
                        }

                        &--products {
                            max-width: 100%;
                            display: flex;
                            gap: 22px;
                            padding: 5px 0;
                            overflow: hidden;

                            @include screen-below(md) {
                                overflow: scroll;
                            }
                        }

                        &__contact {
                            margin-left: -6px;
                        }
                    }

                    .leaflet-container {
                        width: 100%;
                        height: 400px;

                        .leaflet-pane,
                        .leaflet-bottom {
                            z-index: 0;
                        }

                        .leaflet-geosearch-button {
                            visibility: hidden;
                        }

                        .leaflet-top {
                            z-index: 0;
                        }

                        .leaflet-bar {
                            border: none;
                        }
                    }

                    &__with-button {
                        display: flex;
                        justify-content: space-between;

                        button {
                            margin-top: 15px;

                            svg {
                                margin-right: 5px;
                                width: 24px;
                                fill: #fff;
                            }
                        }
                    }

                    &__gradient {
                        display: flex;
                        justify-content: space-between;
                        background: transparent linear-gradient(358deg, var(--unnamed-color-944dc7) 0%, var(--unnamed-color-278982) 100%) 0% 0% no-repeat padding-box;
                        background: transparent linear-gradient(358deg, #944DC7 0%, #278982 100%) 0% 0% no-repeat padding-box;
                        padding: 10px;
                        border-radius: 4px;
                        color: #fff;
                        margin-bottom: 10px;

                        &__info {
                            width: 100%;

                            &--title {
                                margin: 0;
                                font-size: 12px;
                                border-bottom: 1px solid #7a9ab4;
                                padding-bottom: 5px;
                            }

                            &--info {
                                margin: 0;
                                margin-top: 10px;
                                margin-bottom: -4px;
                            }

                            &--call-to-action {
                                color: #fff;
                                font-size: 10px;
                                margin: 0;
                            }
                        }

                        &__image {
                            img {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }

            &__gallery {
                flex: 1;
                display: block;

                @include screen-above(lg) {
                    display: none;
                }
            }

            .overlay {
                position: fixed;
                left: 0;
                right: $right-drawer-width;
                bottom: 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 60px;
                padding: 0 10px;
                background-color: #ffffff;
                box-shadow: 0 -1px 3px 0 rgba(#000000, 0.1);

                @include screen-below(lg) {
                    right: 0;
                }
            }

            &.with-overlay {
                padding-bottom: 60px;
            }
        }

        &__right {
            width: 0;
            overflow-x: hidden;

            @include screen-above(lg) {
                width: $right-drawer-width;
            }
        }
    }
}
.modal{

    &--secondary{
        &__header{
            padding: 10px;
        }
    }

    &__confirm {
        display: flex;
        position: relative;
        z-index: 2;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        background-color: #ffffff;
        overflow: auto;
        margin-top: 50px;
        border-radius: 5px;
    }
}

.profile-error {
    display: flex;
    align-items: center;

    .modal {
        &__content {
            background-color: transparent;
            height: auto;
        }
    }
}

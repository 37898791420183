/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.test-screen {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-maven-regular;
    text-align: center;
    font-size: 13px;
    padding: 50px;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
        padding: 0;
    }

    &__content {
        @extend .test-screen;
        max-width: 600px;
        width: 100%;
        padding: 50px;
        background-color: #ffffff;
        overflow: auto;

        .red-container {
            color: $color-red-EF6262;
            font-family: $font-maven-bold;
            font-size: 15px;
            border: 1px solid $color-red-EF6262;
            padding: 8px 16px;
            margin-bottom: 22px;
        }

        .green-title {
            color: $color-primary;
            font-family: $font-maven-bold;
            font-size: 20px;
            margin-bottom: 22px;
            align-self: flex-start;
        }

        img.logo {
            width: 150px;
            margin-bottom: 22px;
        }

        img.close {
            align-self: flex-end;
            margin-top: -43px;
            cursor: pointer;
        }

        .text {
            margin-bottom: 22px;
        }

        .text-bold {
            margin-bottom: 22px;
            font-family: $font-maven-black;
            font-size: 14px;
        }

        label {
            @extend .control;
            @extend .control--checkbox;
            margin-bottom: 22px;
        }

        .green-btn {
            color: #ffffff;
            background: $color-primary;
            padding: 8px 16px;
            border-radius: 4px;
            font-family: $font-maven-medium;
            font-size: 14px;
            cursor: pointer;
            opacity: 1;
            margin-top: 12px;
            align-items: center;
            display: flex;
            justify-content: center;

            img {
                margin-top: 2px;
                margin-left: 12px;
            }
        }

        .green-btn:hover {
            opacity: 0.7;
        }

        .react-select-container {
            .react-select {
                &__single-value {
                    top: 70%;

                    &--is-disabled {
                        top: 50%;
                    }
                }
            }
        }

        &__repeat {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            color: #999999;
            cursor: pointer;

            img {
                margin-right: 10px;
            }
        }

        &__create {
            margin-bottom: 25px;
            text-decoration: underline;
            color: $color-primary;
            cursor: pointer;
        }

        &__tabs {
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 25px;
        }

        &__result-bar {
            text-align: center;
            margin-bottom: 30px;

            &__overflow {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 300px;
                height: 150px;
                overflow: hidden;
                align-items: center;
                justify-content: flex-end;

                &__bar {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 300px;
                    height: 300px;
                    border: 20px solid #ecf1f1;
                    border-right-color: #eec59b;
                    border-bottom-color: #eec59b;
                    border-radius: 50%;
                    transition: all 0.5s linear;

                    &--high-score {
                        border-right-color: #8ec0bc;
                        border-bottom-color: #8ec0bc;
                    }
                }

                &__score {
                    font-weight: bold;
                    color: $color-primary;
                    font-size: 24px;
                    margin-bottom: 10px;

                    span {
                        margin: 0 5px;
                        color: #cccccc;
                    }
                }

                .btn {
                    margin: 0;
                    text-transform: none;
                    z-index: $modal-button-z-index;
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 0;

            .react-select__menu {
                z-index: $modal-select-z-index;
            }

            .row {
                width: 100%;
                text-align: left;

                h3 {
                    margin: 8px 0;
                    font-weight: normal;
                    color: $color-primary;
                    font-size: 18px;
                }
            }

            .radio-input {
                display: flex;
                align-self: flex-start;
                align-items: flex-start;
                text-align: left;
                margin: 5px 0;

                label {
                    margin: 0;
                    color: #9d9e9e !important;
                    height: auto;
                }

                input[type='radio'] {
                    display: none;
                }

                input[type='radio']+*::before {
                    content: '';
                    display: inline-block;
                    vertical-align: bottom;
                    width: 1.1rem;
                    height: 1.1rem;
                    margin-right: 0.3rem;
                    border-radius: 50%;
                    border-style: solid;
                    border-width: 1px;
                    border-color: gray;
                }

                input[type='radio']:checked+* {
                    color: $color-primary;
                }

                input[type='radio']:checked+*::before {
                    background: radial-gradient($color-primary 0%, $color-primary 40%, transparent 50%, transparent);
                    border-color: $color-primary;
                }
            }
        }
    }
}

.underline-tabs {
    .tab {

        &--active::before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    span {
        position: relative;
        margin: 0 10px;
        padding: 0 35px;
        font-size: 16px;
        cursor: pointer;

        &::before {
            content: "";
            visibility: hidden;
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #278982;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            transition: all .3s ease-in;
        }
    }
}
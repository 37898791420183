/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.home-screen {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50%;
        z-index: -2;
        background-image: url('../../../images/frontpage_right_bg.svg');
        background-position: right top;
        background-repeat: no-repeat;
        background-size: 100% auto;

        @include screen-below('lg') {
            display: none;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 55%;
        width: 100%;
        background-color: #278982;
        transform: skew(-10deg);
        z-index: -1;

        @include screen-below('lg') {
            display: none;
        }
    }

    @include screen-below('lg') {
        flex-wrap: wrap;
        height: auto;
    }

    .left,
    .right {
        position: relative;
        height: 100%;

        @include screen-below('lg') {
            height: auto;
            width: 100% !important;
        }
    }

    .left {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 45%;
        align-items: center;

        @include screen-below('lg') {
            justify-content: center;
            padding-top: 15px;
        }

        &__content {
            margin-right: -50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            font-size: 30px;

            @include screen-below('lg') {
                justify-content: center;
                margin: 0 !important;
                font-size: 22px;
            }

            span {
                padding-left: 22px;
                padding-right: 22px;
                text-align: center;
                flex: 0 0 100%;
                max-width: 100%;
                color: $color-white-F7F7F7;

                @include screen-below('lg') {
                    padding: 10px 0px;
                }

            }

        }

        img {
            max-width: 493px;

            @include screen-below('lg') {
                width: 90% !important;
                margin: 0 !important;
            }
        }

        button {
            position: absolute;
            top: 50px;
            left: 50px;

            @include screen-below('lg') {
                top: 20px;
                left: 20px;
            }
        }

        &::after {
            display: none;
            content: '';
            position: absolute;
            bottom: 15%;
            left: 0;
            width: 100%;
            height: 150%;
            background-color: #278982;
            transform: skewY(10deg);
            z-index: -1;

            @include screen-below('lg') {
                display: block;
            }
        }
    }

    .right {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding: 50px;
        text-align: right;

        @include screen-below('lg') {
            align-items: flex-start;
            text-align: left;
            padding: 0px 22px 30px 22px;
        }

        .top {
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;

            @include screen-below('lg') {
                align-items: flex-start;
                height: auto !important;
            }

            >div {
                display: flex;
            }

            .sportrack-logo {
                width: 250px;
                margin-bottom: 10px;

                @include screen-below('lg') {
                    width: 200px;
                }
            }
        }

        .bottom {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            height: 50%;

            @include screen-below('lg') {
                align-items: flex-start;
                height: auto !important;
            }

            p {
                margin: 10px 0;
                font-family: $font-maven-regular;
                font-size: 18px;
                color: $color-green-001609;

                &.lighter {
                    width: 80%;
                    margin: 0;
                    font-family: $font-maven-medium;
                    font-size: 16px;
                    color: rgba($color-green-001609, 0.65);
                    line-height: 20px;

                    @include screen-below('lg') {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }

            h3 {
                margin: 0;
                font-family: $font-aachen-bt-bold;
                font-size: 16px;
                font-weight: lighter;
                color: #278982;

                span {
                    padding: 0 0 0 15px;

                    @include screen-below('md') {
                        display: block;
                        padding: 5px 0 0 0;
                    }
                }

                @include screen-below('lg') {
                    margin-bottom: 20px;
                }
            }

            h4 {
                margin: 0;
                font-family: $font-aachen-bt-bold;
                font-size: 16px;
                color: $color-green-001609;
            }

            .call-action {
                display: flex;

                @include screen-below('lg') {
                    flex-direction: column;
                    width: 100%;
                }

                >div {
                    p {
                        margin: 0;
                        font-family: $font-maven-regular;
                        font-size: 16px;
                        color: #278982;
                    }
                }

                button {
                    margin-left: 15px;

                    @include screen-below('lg') {
                        margin: 15px 0 0 0;
                    }
                }
            }
        }
    }
}
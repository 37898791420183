/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.form-field {
    position: relative;
    margin-bottom: 30px;

    .input-container {
        display: flex;
        width: 100%;
        border-bottom: 1px solid $color-primary;

        &.has-error {
            border-bottom: 1px solid $color-error;
        }

        input[type='text'],
        input[type='password'],
        textarea {
            flex: 1;
            width: 100%;
            font-family: $font-roboto-regular;
            font-size: 15px;
            background: none;
            border: none;
        }

        textarea {
            resize: none;
            overflow: auto;

            &.phrase {
                font-family: $font-aachen-bt-bold;
                font-size: 22px;
                color: #278982;
            }
        }

        button {
            height: 22px;

            img {
                height: 22px;
            }
        }
    }

    label {
        display: block;
        width: 100%;
        font-family: $font-roboto-medium;
        font-size: 12px;
        color: #9d9e9e;
        height: 16px;
    }
}

.field-error {
    top: 40px;
    font-family: $font-roboto-medium;
    font-size: 11px;
    color: $color-error;
    white-space: normal;
    line-height: 12px;
}

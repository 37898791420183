/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.modal {
    position: fixed;
    display: grid;
    z-index: $nav-z-index + 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    &__backdrop {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#08211f, 0.9);
    }

    &__test {
        @extend .modal__backdrop;
        background-color: rgba(#000, 0.9);
    }

    &--share {
        z-index: 2005;
    }

    &--buy-product {
        align-items: start;
        top: 80px;
        z-index: $nav-z-index - 1;
    }

    &--small {
        margin: auto;
        width: 50%;
        height: 30%;
        display: flex;
    }

    .test {
        max-width: 1000px;
    }

    .shared {
        max-height: 550px;
    }

    @media only screen and (max-width: 600px) {
        .test {
            width: 100%;
            padding: 0;
        }

        .shared {
            top: 25px;
            width: 100%;
            max-height: 85%;
        }
    }

    &__content {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-width: 600px;
        margin: 0 auto;
        background-color: #ffffff;
        overflow: auto;

        &--small {
            justify-content: center
        }

        &__test {
            @extend .modal__content;
            height: unset;
            background-color: transparent;
        }

        &__close {
            position: absolute;
            top: 10px;
            right: 10px;
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            z-index: 1;

            svg {
                path {
                    fill: #fff;
                }
            }
        }

        &__termsConditions {
            padding: 35px;

            .line {
                margin-top: 30px;
                border: 1px solid $color-primary;
                width: 100%;
            }

            .main-title {
                font-family: $font-maven-bold;
                font-size: 15px;
                letter-spacing: 0;
                color: $color-primary;
                margin-top: 20px;
            }

            .sub-title {
                font-family: $font-maven-medium;
                font-size: 14px;
                letter-spacing: 0;
                color: $color-primary;
                opacity: 0.75;
                margin-top: 8px;
                margin-bottom: 8px;
            }

            .description {
                font-family: $font-maven-regular;
                font-size: 13px;
                letter-spacing: 0;
                color: $color-green-001609;
                margin-top: 10px;

                a {
                    margin: 0px;
                }

                button {
                    font-family: $font-maven-regular;
                    font-size: 13px;
                    letter-spacing: 0;
                    color: #278982;
                    text-decoration: underline;
                    margin: 0;
                }
            }

            svg {
                path {
                    fill: $color-primary;
                }
            }
        }

        &__top {

            &__header {
                @include screen-below(sm) {
                    position: relative;
                    height: 200px;
                    width: 100%;
                    background-color: #0c302d;
                }
                position: relative;
                height: 200px;
                width: 555px;
                background-color: #0c302d;

                .logo {
                    position: absolute;
                    top: 30px;
                    left: 30px;
                }

                .running {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                }

                .slant {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                }
            }

            &__subheader {
                padding: 25px 35px;
                background-color: $color-primary;

                h1 {
                    margin: 0;
                    font-family: $font-aachen-bt-bold;
                    font-size: 30px;
                    color: #ffffff;
                }

                p {
                    margin: 0;
                    font-family: $font-maven-medium;
                    font-size: 14px;
                    color: #0c302d;
                }
            }
        }

        form {
            padding: 50px;

            .btn {
                margin: 0;

                &--centered {
                    margin: 0 auto;
                }
            }
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;

            label {
                margin-bottom: 20px;
            }

            p {
                margin: 0;
                font-family: $font-maven-medium;
                font-size: 12px;
                color: #6e6e6e;
                text-align: center;
            }
        }

        .share {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                margin: 0;
                font-family: $font-maven-medium;
                font-size: 14px;
                color: #0c302d;
            }

            .link-url {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 40px;
                width: 100%;

                input {
                    text-align: center;
                    width: 60%;
                    color: $color-primary;
                    text-decoration: underline;
                    font-family: $font-maven-medium;
                    border: none;
                }
                button {
                    cursor: pointer;

                    img {
                        max-width: 24px;
                        max-height: 24px;
                    }
                }
            }

            .social-media {
                display: flex;
                justify-content: flex-end;
                align-content: flex-end;

                a {
                    max-width: 60px;
                    img {
                        height: 40px;
                        margin-right: 20px;
                        cursor: pointer;
                    }

                    img.mail {
                        margin-right: 0;
                    }
                }
            }

            @media only screen and (max-width: 600px) {
                .link-url {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    input {
                        font-size: 12px;
                        margin-bottom: 10px;
                    }
                }
                .social-media {
                    a {
                        max-width: 60px;
                        img {
                            max-width: 40px;
                        }
                    }
                }
            }
        }
    }

    &--secondary {
        max-width: 650px;
        width: 100%;
        height: unset;
        padding: 40px 47px 25px 44px;
        background-color: $color-white-F7F7F7;
        min-width: 400px;

        .MuiTabs-root {
            margin-bottom: 20px;

            .MuiTabs-flexContainer {
                justify-content: center;

                .MuiTab-wrapper {
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
    
                    svg {
                        height: 25px;
                    }
    
                    p {
                        font-size: 19px;
                        font-family: $font-maven-bold;
                        text-transform: none;
                    }
                }
            }

            .MuiTabs-indicator {
                background-color: $color-primary;
            }
        }
        

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > img {
                height: 28px;
            }

            > button {
                margin: 0;
                height: 24px;
            }

            &__title {
                display: flex;
                align-items: center;

                h3 {
                    margin: 0;
                    margin-left: 10px;
                    margin-bottom: 5px;
                    color: $color-purple;
                }
            }
        }

        &__mbway {
            display: inline-flex;
            justify-content: center;
            margin: 15px 0;

            svg {
                width: 50px;
                height: 50px;
                margin-right: 15px;
            }
        }

        &__credits-payment-info {
            margin-bottom: 15px;
            text-align: center;

            span.bold {
                font-family: $font-maven-bold;
            }
        }

        &__title {
            display: flex;
            flex-flow: column;
            margin-top: 20px;

            > span {
                font-family: $font-maven-medium;
                font-size: .85rem;
            }

            > i {
                margin: 20px 0;
            }

            &--margin-bottom {
                margin-bottom: 10px;
            }
        }

        > form {
            padding: 0;
        }

        &__form {
            display: flex;
            flex-direction: column;

            &__input.input-container {
                border-color: $color-grey-A7A7A7;
            }
        }

        &__success {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px 0;

            svg {
                width: 40px;
                height: 40px;
                margin-bottom: 15px;
            }

            p {
                text-align: center;
            }
        }

        &__actions {
            top: 50px;
            width: auto;
            display: flex;
            justify-content: flex-end;

            &--success {
                justify-content: center;
            }

            &--buy-credits-modal {
                .btn-primary {
                    background-color: $color-white-F7F7F7;
                }
            }
        }

        &__body {
            &__payment-modal {
                display: flex;
                flex-direction: column;
                align-items: center;

                .avatar {
                    margin-top: 15px;
                }

                input[type='range'] {
                    width: 100%;
                    -webkit-appearance: none;
                    outline: none;
                    margin-top: 25px;

                    &:focus {
                        outline: none;
                    }

                    &::-webkit-slider-runnable-track {
                        height: 8px;
                        border-radius: 5px;
                        -webkit-appearance: none;
                        margin-top: -1px;
                        background-color: rgba($color-dark-grey, 0.23);
                    }

                    &::-webkit-slider-thumb {
                        width: 23px;
                        -webkit-appearance: none;
                        height: 23px;
                        border-radius: 50%;
                        cursor: pointer;
                        background-color: $color-purple;
                        margin-top: -8px;
                    }

                    &::-moz-range-progress {
                        background-color: $color-purple;
                        height: 8px;
                        border-radius: 5px;
                        margin-top: -1px;
                    }

                    &::-moz-range-track {
                        height: 8px;
                        border-radius: 5px;
                        margin-top: -1px;
                        background-color: rgba($color-dark-grey, 0.23);
                    }

                    &::-moz-range-thumb {
                        border: none;
                        height: 23px;
                        width: 23px;
                        border-radius: 50%;
                        background-color: $color-purple;
                    }

                    &::-ms-track {
                        background-color: transparent;
                        border-color: transparent;
                        border-width: 6px 0;
                        color: transparent;
                    }

                    &::-ms-fill-lower {
                        background-color: $color-purple;
                        border-radius: 5px;
                    }

                    &::-ms-fill-upper {
                        background-color: rgba($color-dark-grey, 0.23);
                        border-radius: 5px;
                    }

                    &::-ms-thumb {
                        border: none;
                        width: 23px;
                        height: 23px;
                        cursor: pointer;
                        background-color: $color-purple;
                        border-radius: 50%;
                    }
                }

                &__message {
                    margin-top: 17px;
                    color: $color-purple;

                    &--name {
                        color: $color-green-001609;
                    }
                }

                &__months {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: solid 1px $color-purple;
                    border-radius: 15px;
                    color: $color-purple;
                    height: 30px;
                    padding: 10px;
                    margin-top: 27px;
                }

                &__for {
                    font-size: 9px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

                &__credits {
                    font-size: 30px;
                    font-weight: bold;
                    margin: 0px;
                    color: $color-purple;
                }

                &__credits-avaliable {
                    display: flex;
                    margin: 17px 0;

                    p {
                        margin: 0;
                    }

                    &__alert {
                        color: $color-error;
                    }
                }

                &__info {
                    font-size: 9px;
                    color: rgba(16, 16, 16, 0.31);
                }

                &__terms {
                    display: flex;
                    align-items: center;
                    margin-top: 12px;

                    input {
                        margin-right: 7px;
                    }

                    p {
                        margin: 0;
                        font-size: 9px;
                        color: $color-green-001609;

                        button {
                            font-family: $font-roboto-medium;
                            font-size: 9px;
                            color: #278982;
                            text-decoration: underline;
                            margin: 0;
                        }
                    }
                }

                button:disabled {
                    cursor: not-allowed;
                    background-color: rgba(148, 77, 199, 0.2);
                }
            }
        }
    }
}
/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */


.menu-options {
    background-color: $color-dark-grey;
    position: absolute;
    right: -10px;
    padding: 10px 9px;
    text-align: center;
    border-radius: 4px;
    transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 185ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    opacity: 0;
    transform: scale(.1);
    transform-origin: right top 0px;

    &--opened {
        opacity: 1;
        z-index: $zindex-options;
        transform: scale(1)
    }

    > button {
        width: 100%;
        margin: 0;
        color: white;
        white-space: nowrap;
        padding: 0 22px;
    }

    > hr {
        border: 0;
        border-bottom: 1px solid rgba(#E0E0E0, .35);

        &:first-of-type {
            display: none;
        }
    }
}
/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

html,
body {
    height: 100vh;
    margin: 0;
    padding: 0;
}

body {
    font-family: $font-maven-medium;
    color: #000000;
    background-color: #ffffff;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: border-box;
}

*,
*:focus {
    outline: 0 !important;
}

a {
    color: $color-primary;
}

input[type='file'] {
    display: none;
}

button,
a {
    margin: 0 0 0 10px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;

    &.share,
    &.contact {
        height: 36px;
        padding-right: 13px;
    }

    &.share,
    &.contact,
    &.close {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-maven-medium;
        font-size: 14px;
        text-transform: uppercase;
        color: $color-primary;
        border: none;
        border-radius: 4px;
    }

    &.share {
        background-color: $color-green-001609;
        fill: $color-primary;

        &--white {
            background-color: #ffffff;
        }

        &--light {
            background-color: $color-primary;
            fill: #fff;
        }

        &--inverted {
            fill: $color-primary;
            background-color: #fff;
            border: 2px solid $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.contact {
        color: $color-green-001609;
        background-color: $color-primary;

        &--white {
            background-color: #ffffff;
        }
    }

    &.close {
        background-color: $color-green-001609;
    }
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 0 30px 1px 30px;
    font-family: $font-maven-medium;
    font-size: 14px;
    text-transform: uppercase;
    color: $color-green-001609;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &--inverse {
        background-color: $color-green-001609;
        color: #ffffff;
    }

    &--primary {
        background-color: #ffffff;
        color: $color-primary;
    }

    &--primary-inverse {
        background-color: $color-primary;
        color: #ffffff;
    }

    &--purple {
        background-color: $color-purple;
        color: #ffffff;
    }

    &--white-border {
        color: #ffffff;
        background: transparent;
        border: 1px solid #ffffff;
    }

    &--primary-dark {
        background-color: $color-primary-dark;
        color: #ffffff;
    }

    &--primary-light {
        background-color: $color-primary-light;
        color: #ffffff;
    }

    &--warning {
        background-color: $color-error;
        color: #ffffff;
    }

    &--warning-outline {
        background-color: transparent;
        border: 1px solid $color-error;
        color: #ffffff;
    }

    &--block {
        width: 100%;
    }
}

.hidden-lg {
    @include screen-above(lg) {
        display: none !important;
    }
}

.hidden-md {
    @include screen-between(md, lg) {
        display: none !important;
    }
}

.hidden-sm {
    @include screen-between(sm, md) {
        display: none !important;
    }
}

.hidden-xs {
    @include screen-below(sm) {
        display: none !important;
    }
}

// layout

#root,
.app-container,
.app-router,
.theme-container {
    height: 100vh;
}

.theme-container {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.index-screen,
.content-area {
    height: 100%;
}

// elements

.top-menu {
    display: flex;

    .btn {
        &:not(.btn--primary-dark):not(.btn--primary-inverse) {
            background-color: transparent;
            border: 1px solid $color-primary;
            color: $color-primary;
        }
    }

    .btn--primary-dark {
        padding: 0 15px;

        img {
            margin-right: 15px;
            margin-bottom: 0 !important;
        }
    }

    @include screen-below('lg') {
        display: none !important;
    }
}

.bubble-box {
    position: relative;
    width: 462px;
    background-color: $color-primary;

    >img {
        position: absolute;
        top: 25px;
        left: -57px;
        z-index: 0;
    }

    &.reverse {
        >img {
            left: auto;
            right: -57px;
        }
    }

    >div {
        display: flex;
        flex-direction: column;
        z-index: 1;

        .top {
            padding: 10px 60px 20px 60px;

            h2 {
                margin: 15px 0 0 0;
                font-family: $font-aachen-bt-bold;
                font-size: 32px;
                text-align: center;
                color: #ffffff;
            }

            p {
                margin: 19px 0;
                text-align: center;
                line-height: 20px;
                font-family: $font-maven-bold;
                font-size: 16px;
                color: #ffffff;

                >span {
                    font-family: $font-maven-medium;
                    font-size: 14px;
                }

                &.subtitle {
                    margin: 10px 0 25px 0;
                    font-family: $font-maven-bold;
                    font-size: 14px;
                    line-height: normal;
                    color: #00230e;
                }
            }
        }

        .bottom {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            background-color: #103f34;

            button {
                margin-top: -28px;
                margin-bottom: 5px;
                color: $color-primary;
            }

            p {
                margin: 5px 0;
                text-align: center;
                font-family: $font-maven-bold;
                font-size: 14px;
                color: #ffffff;

                span {
                    font-family: $font-maven-regular;
                }
            }
        }
    }
}

.plan {
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
        margin: 15px 0;
        font-family: $font-aachen-bt-bold;
        font-size: 42px;
        line-height: 40px;
        color: #ffffff;

        span {
            font-family: $font-maven-regular;
            font-size: 27px;
        }
    }

    .description {
        flex: 1;

        p {
            margin: 25px 0;
            font-family: $font-maven-medium;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;

            &.and {
                font-family: $font-aachen-bt-bold;
                font-size: 16px;
                color: #000000;
            }
        }
    }

    .bottom {
        height: 120px;

        >p {
            margin: 10px 0;
            font-family: $font-maven-bold;
            font-size: 16px;
            color: rgba(#ffffff, 0.65);
        }

        >div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            >div {
                position: relative;
                width: 50%;
                height: 80px;
                padding: 15px 20px;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 15px;
                    width: 100%;
                    transform: skew(-20deg);
                    background-color: rgba(#ffffff, 0.35);
                    z-index: 0;
                }

                >div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: 15px 20px;
                    z-index: 1;

                    p {
                        margin: 0;
                        font-family: $font-maven-bold;
                        font-size: 32px;
                        line-height: 28px;
                        color: #ffffff;

                        span {
                            font-size: 18px;
                        }

                        &.small {
                            font-family: $font-maven-regular;
                            font-size: 14px;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }
}

.control {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    font-family: $font-roboto-medium;
    font-size: 14px;
    color: rgba(#303030, 0.6);

    .theme-dark & {
        color: #999999;
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .indicator {
        position: relative;
        height: 18px;
        width: 18px;
        margin-right: 10px;
        background-color: #ffffff;
        border: 1px solid #cccccc;

        .theme-dark & {
            background-color: #404040;
        }

        &:after {
            content: '';
            position: absolute;
            display: none;
        }
    }

    &.control--checkbox {
        .indicator:after {
            left: 5px;
            top: 2px;
            width: 3px;
            height: 8px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

        input:checked~.indicator {
            background: $color-primary;
            border: 1px solid $color-primary;
        }

        input:disabled~.indicator:after {
            border-color: #7b7b7b;
        }

        button {
            font-family: $font-maven-medium;
            font-size: 12px;
            color: #278982;
            text-decoration: underline;
            margin: 0;
        }
    }

    &.control--radio {
        .indicator {
            height: 22px;
            width: 22px;
            border: 2px solid #cccccc;
            border-radius: 50%;
        }

        .indicator:after {
            left: 2px;
            top: 2px;
            height: 14px;
            width: 14px;
            border-radius: 50%;
            background: $color-primary;
        }

        input:disabled~.indicator:after {
            background: #7b7b7b;
        }
    }

    input:disabled~.indicator {
        opacity: 0.6;
        pointer-events: none;
    }

    input:checked~.indicator:after {
        display: block;
    }
}

.title {
    position: relative;
    width: min-content;
    margin: 20px 0;
    font-family: $font-maven-bold;
    font-size: 14px;
    color: $color-primary;

    &:before {
        position: absolute;
        top: -14px;
        left: 0;
        content: '';
        width: 30px;
        height: 7px;
        background-color: $color-primary-dark;
        opacity: 0.75;
    }

    &:after {
        content: '';
        position: absolute;
        top: -14px;
        left: 30px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 0 0 7px;
        border-color: transparent transparent transparent $color-primary-dark;
        opacity: 0.75;
    }

    &--alternative {
        color: #ffffff;

        &:before {
            background-color: $color-primary;
        }

        &:after {
            border-color: transparent transparent transparent $color-primary;
        }
    }
}

// sections

.footer {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 0 22px 0 22px;

    @include screen-below('sm') {
        padding: 30px 0 0 0;
    }

    &__top-container {
        display: flex;
        justify-content: space-between;

        >img {
            top: 0;
            right: 20px;

            @include screen-below('md') {
                display: none;
            }
        }
    }

    a {
        text-decoration: none;
    }

    button {
        margin: 5px 0;
        background-color: #278982;
        color: #ffffff;
        padding: 0 15px;

        img {
            width: 24px;
            margin-right: 10px;
        }
    }

    .details {
        display: flex;
        align-items: center;

        @include screen-below('sm') {
            flex-wrap: wrap;
            padding: 0 22px;
        }

        >img {
            margin: 12px;

            @include screen-below('sm') {
                margin: 0;
            }
        }

        .contacts {
            padding: 55px 35px 25px 35px;
            align-self: flex-start;

            @include screen-below('sm') {
                width: 100%;
                padding: 25px 0;
            }

            p {
                margin: 0 0 20px 0;
                font-family: $font-maven-medium;
                font-size: 16px;
                color: #2b2d2c;
                line-height: 22px;

                &:last-of-type {
                    margin: 0;
                }

                a {
                    margin: 0;
                }
            }
        }
    }

    .links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 0 8px 0;
        font-family: $font-maven-regular;
        font-size: 14px;
        color: #001608;

        @include screen-below('sm') {
            height: auto;
            padding: 0 22px;
        }

        >div {
            display: flex;
            align-items: center;

            @include screen-below('sm') {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            button {
                padding: 0 5px;
                margin-left: 10px;
                font-family: $font-maven-medium;
                color: #3eb1a8;
                background-color: #fff;
                text-decoration: none;
                font-size: 14px;

                @include screen-below('sm') {
                    padding: 5px 0 0 0;
                }
            }
        }

        .credits {
            z-index: 2;

            @include screen-below('md') {
                display: none;
            }
        }
    }

    .bottom {
        position: relative;
        width: 100%;

        >img {
            max-width: 100%;
            max-height: 200px;
        }

        >div {
            position: absolute;
            bottom: 10px;
            right: 30px;
            font-family: $font-maven-regular;
            font-size: 14px;
            color: #001608;
            z-index: 1;
        }

        @include screen-above('md') {
            display: none;
        }
    }
}

.tabs {
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;

    .tab-item {
        cursor: pointer;

        .title {
            font-size: 14px;
            font-family: $font-maven-medium;
            letter-spacing: 0;
            color: #182b20;
            opacity: 0.36;

            &-active {
                opacity: 1;
            }

            &--contacts {
                font-size: 24px;
                margin-bottom: 30px;
                color: $color-primary-dark;
            }
        }

        .active {
            display: flex;
            margin-top: 8px;

            .active-start {
                width: 30px;
                height: 7px;
                background: #278982 0% 0% no-repeat padding-box;
                opacity: 0.75;
            }

            .active-end {
                border-style: solid;
                border-width: 7px 0 0 7px;
                border-color: transparent transparent transparent #278982;
                opacity: 0.75;
            }
        }
    }
}

.circle-arrow {
    width: 30px;
    height: auto;

    &--inverse {
        transform: rotate(180deg);
    }
}

.loader-backdrop {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: grid;
    place-items: center;
    z-index: $zindex-loader;
    background-color: rgba(0, 0, 0, 0.4);
}

.loader {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: grid;
    place-items: center;
    z-index: $zindex-loader;
}
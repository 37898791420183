/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.topbar {
    display: flex;
    position: fixed;
    z-index: $nav-z-index;
    top: 0;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 10px;
    background-color: $color-primary;

    >button {
        margin: 0 10px 0 0;
    }

    &__search {
        display: flex;
        justify-content: center;
        width: 100%;

        >:first-child {
            max-width: 75%;
        }
    }

    .logo {
        @include screen-below(sm) {
            display: none;
        }
    }

    .logo-short {
        @include screen-above(sm) {
            display: none;
        }
    }

    &__right {
        text-wrap: nowrap;
        display: flex;
        justify-content: flex-end;

        &__about {
            @include screen-below(lg) {
                display: none;
            }
        }

        &__network {
            @include screen-below(lg) {
                display: none;
            }
        }

        &__signup {
            @include screen-below(lg) {
                display: none;
            }
        }

        &__sportest {
            @include screen-below(xs) {
                display: none;
            }
        }

        &__sportest--before {
            display: none;
        }

        &__burger {
            display: none;

            @include screen-below(lg) {
                display: block;
            }

            &--auth {
                @include screen-above(xs) {
                    display: none;
                }
            }
        }

        .user {
            display: flex;
            align-items: center;
            cursor: pointer;

            @include screen-below(xs) {
                display: none;
            }

            div {
                display: flex;
                flex-direction: column;
                align-content: flex-end;
                text-align: right;
            }

            span {
                margin: 0 10px;
                font-size: 13px;
                text-decoration: none;
                white-space: nowrap;
                color: #ffffff;
            }

            .avatar {
                cursor: pointer;
            }
        }
    }

    &__center {
        display: flex;
        column-gap: 20px;
        justify-content: space-between;
        align-items: center;

        .market-tab {
            color: $color-green-001609;
            fill: $color-green-001609;
            text-decoration: none;
            height: 100%;

            &--active {
                color: #fff;
                border-bottom: 3px solid $color-dark-green;
                border-right: 3px solid transparent;
                padding: 10px;

                svg {
                    fill: #fff;
                }
            }
        }
    }
}

.sidebar {
    display: none;
    position: fixed;
    z-index: $nav-z-index;
    top: 50px;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    width: 0;
    overflow: hidden;
    background-color: $color-primary-dark;
    transition: width 0.3s linear;

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px;

        &.logout {
            justify-content: center;
        }

        &__middle {
            display: flex;
            flex-direction: column;
        }
    }

    &__user {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;

        span {
            text-align: center;
            padding: 0 30px;
            margin-top: 10px;
            margin-bottom: -5px;
            font-size: 13px;
            color: #ffffff;
        }
    }

    @include screen-below(lg) {
        display: flex;
    }

    .btn {
        margin-bottom: 15px;
    }

    &__sportest {
        display: none;
    }

    &__sportest--before {
        display: none;
        background: $color-primary;

        @include screen-below(xs) {
            display: block;
        }
    }

    &__login {
        display: none;
    }

    &__logout {
        margin-top: 20px;
        font-size: 12px;
        text-align: center;
        text-decoration: underline;
        color: #ffffff;
        opacity: 0.4;
        cursor: pointer;
    }

    &--open {
        width: 250px;
    }

    &--auth {
        @include screen-above(xs) {
            display: flex;
            bottom: auto;
            width: 250px;
            max-height: 0;
            transition: max-height 0.3s linear;

            &.sidebar--open {
                max-height: 100%;
            }

            .sidebar__close {
                display: none;
            }
        }
    }

    &__close {
        position: absolute;
        top: 25px;
        left: 25px;
        width: 30px;
        cursor: pointer;
    }

    .small-buttons-container {
        display: flex;
        justify-content: space-between;

        .contact {
            margin-right: 10px;
        }

        .share { 
            width: 100%;
        }
    }
}
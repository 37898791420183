/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.sponsorship-manager {
    &-table {
        position: relative;
        width: 100%;
        text-align: left;
        border-collapse: collapse;

        .lottie {
            position: absolute;
            top: 0;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
            pointer-events: none;
        }

        tr {
            border-bottom: 1px solid rgba($color-grey-A7A7A7, 0.5);
        }

        th {
            font-weight: normal;
            color: $color-purple;
            padding: 15px 0;
        }

        td {
            padding: 15px 0;
            font-weight: bold;
        }

        &__column-30 {
            width: 30%;
        }

        &__column-20 {
            width: 20%;
        }

        &__column-10 {
            width: 10%;
        }

        &__column-username {
            display: flex;
            align-items: center;
            color: $color-purple;

            img {
                width: 30px;
                margin-right: 10px;
            }

            a {
                display: flex;
                align-items: center;
                text-decoration: none;
            }
        }

        &__column-status {
            display: flex;
            
            &--rejected, &--canceled, &--expired,  &--closed, &--cancelled_by_supporter {
                color: $color-error;
            }

            &--pending {
                color: $color-light-orange;
                align-items: center;
            }

            &--active {
                color: $color-primary;
            }

            &--btn-cancel {
                vertical-align: middle;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
            
        }

        &__column-actions {
            display: flex;
            margin-left: 15px;

            button {
                display: flex;
                align-items: center;
                width: 21px;
                height: 21px;
                border-radius: 4px;
            }

            &--btn-accept {
                background-color: $color-primary;

                img {
                    width: 15px;
                    height: 15px;
                }
            }

            &--btn-reject {
                background-color: $color-error;

                img {
                    width: 7px;
                    height: 7px;
                }
            }

            
        }

        &__pagination {
            .table-pagination {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-top: 20px;
                font-family: $font-roboto-regular;
                color: $color-grey-aaaaaa;

                &__container {
                    display: flex;
                    grid-gap: 20px;

                    .form-field {
                        width: 50px;
                    }
                }
            }
        }

        &__actions {
            position: absolute;
            top: 0;
            left: 0;
        }

        &__column-period {
            display: flex;

            &--bought {
                padding-right: 7px;
                width: 85px;
            }

            &--left {
                border-left: 1px solid $color-purple;
                padding-left: 7px;

                p {
                    font-size: 12px !important;
                    font-weight: normal;
                    color: $color-purple !important;
                }
            }
        }

        &__column-actions {
            button {
                position: relative;
                left: -20px;

                img {
                    width: 25px;
                }
            }

            &--btn-side {
                top: 11px;
            }
        }
    }
}

.MuiMenu-list {
    background-color: rgba(0,0,0, 0.8);
    color: $color-white-F7F7F7;

    hr {
        width: 80%;
        border: 1px solid rgba(255,255,255, 0.3);
    }

    li {
        &:hover {
            background-color: rgba(0,0,0, 0.5);
        }
    }
}

.MuiMenu-paper {
    top: 310px;
    left: 60px;
}
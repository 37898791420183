/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

.payment-modal {
    position: fixed;
    display: grid;
    z-index: $nav-z-index + 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    &__backdrop {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#08211f, 0.9);
    }

    &__container {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-width: 600px;
        margin: 0 auto;
        background-color: #ffffff;
        overflow: auto;

        form {
            padding: 50px;

            .btn {
                margin: 0;

                &--centered {
                    margin: 0 auto;
                }
            }
        }
    }

    &__content {
        width: 650px;
        height: 650px;
        padding: 40px 47px 25px 44px;
        background-color: $color-white-F7F7F7;
        
        @include screen-below('xs') {
            width: 330px;
            height: 640px;
        }

        .MuiPaper-root {
            background-color: #f7f7f7;
        }
    
        .MuiStepIcon-root.MuiStepIcon-active {
            color: $color-primary
        }
        
        .MuiStepIcon-root.MuiStepIcon-completed {
            color: $color-primary
        }

        .MuiStepper-root {
            padding: 25px 25px 30px 25px;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > img {
                height: 28px;
            }

            > button {
                margin: 0;
                height: 24px;
            }

            &__title {
                display: flex;
                align-items: center;

                h3 {
                    margin: 0;
                    margin-left: 10px;
                    margin-bottom: 5px;
                    color: $color-purple;
                }
            }

            svg {
                path {
                    fill: $color-primary;
                }
            }
        }

        &__body {
            display: grid;
            grid-template-rows: auto 1fr;
            height: 500px;
            padding: 20px 0;

            &__steps {
                padding: 20px 0;
                
                &__content {
                    height: 345px;
                    overflow-y: auto;
                }
            }

            &__tabs {
                display: flex;
                justify-content: center;
                padding-bottom: 30px;
                gap: 30px;

                @include screen-below('xs') {
                    gap: 5px;

                    p { 
                        display: none;
                    }
                }
            }
            
            &__credits-payment-info {
                margin-bottom: 15px;
                text-align: center;
                padding: 25px 0;
    
                span.bold {
                    font-family: $font-maven-bold;
                }
            }

            > form {
                padding: 0;
            }
    
            &__form {
                display: flex;
                flex-direction: column;
                padding: 20px 40px;
                
                @include screen-below('xs') {
                    padding: 15px 20px;
                }

                .form-field {
                    margin: 0;

                    .field-error {
                        position: absolute;
                    }
                }
    
                &__input.input-container {
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }

                .react-select-container .react-select__control {
                    background-color: #f7f7f7;
                }
            }

            &__review {
                margin: 0 auto 15px auto;
                padding: 25px 20px;
                border: 1px solid #ccc;
                border-radius: 5px;
                width: 430px;
                
                @include screen-below('xs') {
                    width: 220px;
                }

                span.bold {
                    font-family: $font-maven-bold;
                }

                span.details {
                    font-family: $font-maven-medium;
                }
                
                &__invoice {
                    display: flex;
                    flex-direction: column;
                    margin: 0 auto 15px auto;
                    padding: 25px 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    width: 430px;
                    
                    @include screen-below('xs') {
                        width: 220px;
                    }

                    span.bold {
                        font-family: $font-maven-bold;
                    }

                    span.details {
                        margin-left: 30px;
                        font-family: $font-maven-medium;

                        @include screen-below('xs') {
                            margin: 0;
                        }
                    }
                }

                &__terms {
                    display: flex;
                    justify-content: center;

                    p {
                        font-family: $font-maven-medium;
                        font-size: 10px;
                    }

                    button {
                        font-family: $font-maven-medium;
                        font-size: 10px;
                        color: $color-primary;
                        text-decoration: underline;
                        margin: 0 5px;
                    }
                }
            }

            &__success {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 85px 0;
                height: 500px;
    
                svg {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 15px;
                }
    
                p {
                    text-align: center;
                }
            }
        }

        &__footer {
            top: 50px;
            width: auto;
            display: flex;
            justify-content: flex-end;

            &__success {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.payment-selection-container {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.custom-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #f7f7f7;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 8px 16px;
    align-items: center;
    cursor: pointer;
    transition: border-color 0.3s;
    width: 200px;
    height: 150px;
    gap: 20px;
    margin: 0;

    @include screen-below('xs') {
        width: 100px;
        height: 100px;
    }
}

.custom-button-credit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #f7f7f7;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 30px 10px;
    align-items: center;
    cursor: pointer;
    transition: border-color 0.3s;
    width: 200px;
    height: 150px;
    gap: 40px;
    margin: 0;

    @include screen-below('xs') {
        width: 100px;
        height: 100px;
    }
}

.custom-button, .custom-button-credit {
    svg {
        width: 100px;

        @include screen-below('xs') {
            width: 65px;
        }
    }

    p {
        margin: 0 0 0 8px;
        font-size: 16px;
        font-family: "MavenPro-Bold", sans-serif;
    }

    &.active {
        border-color: $color-primary;
        background-color: #fff;
    }

    &:hover {
        background-color: #f0f0f0;
    }

    &:active {
        background-color: #fff;
    }
}

/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

// MAVEN

@font-face {
    font-family: 'MavenPro-Black';
    src: url('../../fonts/MavenPro-Black.eot?#iefix') format('embedded-opentype'),  url('../../fonts/MavenPro-Black.woff') format('woff'), url('../../fonts/MavenPro-Black.ttf')  format('truetype'), url('../../fonts/MavenPro-Black.svg#MavenPro-Black') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MavenPro-Bold';
    src: url('../../fonts/MavenPro-Bold.eot?#iefix') format('embedded-opentype'),  url('../../fonts/MavenPro-Bold.woff') format('woff'), url('../../fonts/MavenPro-Bold.ttf')  format('truetype'), url('../../fonts/MavenPro-Bold.svg#MavenPro-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MavenPro-Medium';
    src: url('../../fonts/MavenPro-Medium.eot?#iefix') format('embedded-opentype'),  url('../../fonts/MavenPro-Medium.woff') format('woff'), url('../../fonts/MavenPro-Medium.ttf')  format('truetype'), url('../../fonts/MavenPro-Medium.svg#MavenPro-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MavenPro-Regular';
    src: url('../../fonts/MavenPro-Regular.eot?#iefix') format('embedded-opentype'),  url('../../fonts/MavenPro-Regular.woff') format('woff'), url('../../fonts/MavenPro-Regular.ttf')  format('truetype'), url('../../fonts/MavenPro-Regular.svg#MavenPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// AACHEN

@font-face {
    font-family: 'AachenBold';
    src: url('../../fonts/AachenBold.eot?#iefix') format('embedded-opentype'),  url('../../fonts/AachenBold.woff') format('woff'), url('../../fonts/AachenBold.ttf')  format('truetype'), url('../../fonts/AachenBold.svg#AachenBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AachenBT-Bold';
    src: url('../../fonts/AachenBT-Bold.eot?#iefix') format('embedded-opentype'),  url('../../fonts/AachenBT-Bold.woff') format('woff'), url('../../fonts/AachenBT-Bold.ttf')  format('truetype'), url('../../fonts/AachenBT-Bold.svg#AachenBT-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

// ROBOTO

@font-face {
    font-family: 'robotoblack';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/roboto-black-webfont.woff2') format('woff2'),
    url('../../fonts/roboto-black-webfont.woff') format('woff');
}

@font-face {
    font-family: 'robotobold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/roboto-bold-webfont.woff2') format('woff2'),
    url('../../fonts/roboto-bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'robotolight';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/roboto-light-webfont.woff2') format('woff2'),
    url('../../fonts/roboto-light-webfont.woff') format('woff');
}

@font-face {
    font-family: 'robotomedium';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/roboto-medium-webfont.woff2') format('woff2'),
    url('../../fonts/roboto-medium-webfont.woff') format('woff');
}

@font-face {
    font-family: 'robotoregular';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/roboto-regular-webfont.woff2') format('woff2'),
    url('../../fonts/roboto-regular-webfont.woff') format('woff');
}

@font-face {
    font-family: 'robotothin';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/roboto-thin-webfont.woff2') format('woff2'),
    url('../../fonts/roboto-thin-webfont.woff') format('woff');
}


// MONTSERRAT
@font-face {
    font-family: 'montserratitalic';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/montserrat-mediumitalic-webfont.woff2') format('woff2'),
    url('../../fonts/montserrat-mediumitalic-webfont.woff') format('woff');
}
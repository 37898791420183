/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */


.switch {
    display: flex;
    align-items: center;
    gap: 8px;

    &__toggle {
        position: relative;
        display: inline-block;
        width: 42px;
        height: 24px;

        > input[type='checkbox'] {
            &:checked + .switch__toggle__slider {
                background-color: $color-primary;

                &::before {
                    transform: translateX(100%);
                }
            }
        }
        
        &__slider {
            position: absolute;
            inset: 0;
            background-color: #ccc;
            transition: .3s;
            cursor: pointer;
            border-radius: 18px;


            &::before {
                position: absolute;
                content: "";
                height: calc(100% - 6px);
                aspect-ratio: 1;
                left: 3px;
                bottom: 3px;
                background-color: white;
                transition: .3s;
                border-radius: 18px;
            }
        }
    }

    &__label {
        display: flex;
        flex-flow: column;

        > label,
        > span {
            font-size: 12px;
            font-family: $font-roboto-medium;
            line-height: 14px;
        }

        > label {
            color: rgba(black, .38);
        }
    }
}
/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.error-screen {
    padding: 20px;

    h1 {
        font-size: 36px;
    }

    p {
        font-size: 18px;
    }

    &__buttons-group {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
            background-color: $color-primary;
            color: #fff;
            font-size: 22px;
            padding: 10px 20px;
            border-radius: 6px;
            margin: 0;
        }
    }
}

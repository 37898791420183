.market-screen {
    display: flex;
    flex-direction: column;
    background-color: $color-green-001609;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    fill: $color-primary;
    color: #fff;
    background-image: url('../../../images/market_bg.png');
    background-size: cover ;
    
    &__cards {
        width: 50%;
        justify-self: center;
        display: flex;
        column-gap: 10px;

        @media only screen and (max-width: 1000px) {
            padding: 0;
            max-width: 400px;
            flex-wrap: wrap;
            row-gap: 30px;
            width: 80%;
        }

        .card { 
            color: #fff;
            background-color: $color-dark-green;
            width: 100%;
            min-height: 450px;
            padding: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            hr {
                width: 100%;
                border: 1px dashed $color-lighter-grey;
            }

            .extraFeatures {
                display: inline-flex;
                flex-wrap: wrap;
            }
            
            .plus-icon {
                width: 16px;
                height: 16px;
                margin-left: -4px;
            }
            .topSeller {
                position: relative;
                fill: $color-pale-gold;
                span {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin-right: -20px;
                    margin-top: -20px;
                }

                &--gold {
                    fill: $color-gold;
                }
            }
            .planName {
                color: $color-lighter-grey;
                
                h4 {
                    height: 46px;
                }

                &--mid {
                    color: $color-pale-gold;
                }

                &--gold {
                    color: $color-gold;
                }

                &--diamond {
                    color: $color-diamond;
                }
            }

            .credits {
                background-color: $color-lighter-grey;
                border-radius: 7px;
                width: fit-content;
                padding: 2px 5px;
                align-self: flex-end;
                font-size: 10px;

                &--mid {
                    background-color: $color-pale-gold;
                }

                &--gold {
                    background-color: $color-gold;
                }

                &--diamond {
                    background-color: $color-diamond;
                }
            }

            .planFeatures { 
                padding-left: 0;
                list-style: none;
                font-size: 16px;
                height: 100%;

                svg { 
                    padding-top: 4px;
                    height: 16px;
                    width: 16px;
                }
                li:not(:last-child) { 
                    margin-bottom: 10px;  
                }
                
                &--low {
                    fill: $color-lighter-grey;
                }
                &--mid {
                    fill: $color-pale-gold;
                }
                
                &--gold {
                    fill: $color-gold;
                }

                &--diamond {
                    fill: $color-diamond;
                }
            }

            .chooseCredits {
                font-size: 10px;
                margin-bottom:  10px;
                color: $color-lighter-grey;
                text-transform: capitalize;
            }


            .priceBox{
                display: flex;
                flex-direction: column;
                
                .priceTerms {
                    display: inline-flex; 
                    column-gap: 5px;
                    align-items: center;

                    &--credits {
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    &--mid {
                        color: $color-pale-gold;
                        fill: $color-pale-gold;
                    }
            
                    &--gold {
                        color: $color-gold;
                        fill: $color-gold;
                    }

                    &--diamond {
                        color: $color-diamond;
                        fill: $color-diamond;
                    }
            
                    .price { 
                        font-size: 26px;
                    }

                    .duration {
                        font-size: 9px;
                        margin: 0;

                        &--darker {
                            filter: brightness(0.5);
                        }

                    }

                }

                .subscribe { 
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid $color-lighter-grey;
                    color: $color-lighter-grey;
                    text-align: center;
                    margin-left: 0;
                    padding: 5px;

                    &--mid {
                        border-color: $color-pale-gold;
                        color: $color-pale-gold;
                    }

                    &--gold {
                        border-color : $color-gold;
                        color : $color-gold;
                    }

                    &--diamond {
                        border-color: $color-diamond;
                        color: $color-diamond;
                    }
                }
            }
        }

        .creditAmount {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-transform: uppercase;
            
            p:first-of-type {
                padding-left:0 ;
                margin: 0;
                font-size: 72px;
                font-family: $font-maven-black;

                @media only screen and (max-width: 1376px) {
                    font-size: 49px;
                }
            }

            color: $color-lighter-grey;

            &--gold {
                color: $color-gold;
            }

            &--diamond {
                color: $color-diamond;

                p span {
                    color: $color-diamond;
                    font-size: 12px;
                }
            }

            span {
                font-size: 12px;
                color: #fff;
            }
        }
    }

    .header {
        width: 50%;
        margin-bottom: 30px;
        margin-top: 90px;   

        p {
            color: $color-primary;
            font-size: 14px;

            a { 
                color: #fff;
                margin: 0;
            }
        }

        svg {
            height: 22px;
        }
    }

    .exitStore__button{
        border-radius: 4px;
        border: 1px solid $color-primary;
        padding: 5px;
        font-size: 14px;
        text-transform: uppercase;
        display: inline-flex;
        column-gap: 5px;
        align-items: center;
        color: $color-primary;
        margin-bottom: 50px;
    }

    .termsAndConditions {
        font-size: 9px;

        span { 
            text-decoration: underline;
            cursor: pointer;
        }
    }

    input[type='range'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &:focus {
          outline: none;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
        }
        &::-moz-range-thumb {
          border: none;
        }
      }
      
      .slider {
        width: 100%;
        background: $color-dark-green;
        margin-top: 6px;


        @mixin track-style {
          display: flex;
          align-items: center;
          height: 1px;
          border-top: 1px solid $color-diamond;
        }
      
        @mixin thumb-style {
          position: relative;
          margin-top: -8px;
          width: 15px;
          height: 15px;
          background-color: #fff;
          border-radius: 50%;
        }
        &::-webkit-slider-runnable-track {
          @include track-style;
          margin-bottom: 8px;
        }
        &::-webkit-slider-thumb {
          @include thumb-style;
          margin-bottom: 8px;

        }
        &::-moz-range-track {
          @include track-style;
          margin-top: 10px;
        }
        &::-moz-range-thumb {
          @include thumb-style;
          margin-top: 10px;
        }

        /* Safari 11+ */
        @media not all and (min-resolution:.001dpcm)
        { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
            &::-webkit-slider-runnable-track {
                @include track-style;
                margin-bottom: 8px;
            }
            &::-webkit-slider-thumb {
                @include thumb-style;
                margin-bottom: 8px;
                margin-top: 8px;

            }
        }}

        /* Safari 10.1 */
        @media not all and (min-resolution:.001dpcm){ 
        @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
        &::-webkit-slider-runnable-track {
                @include track-style;
                margin-bottom: 8px;
            }
            &::-webkit-slider-thumb {
                @include thumb-style;
                margin-bottom: 8px;
                margin-top: 8px;

            }
        }}

      }
}
/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */


.product-modal {
    max-width: 650px;
    width: 100%;
    height: unset;
    padding: 40px 47px 25px 44px;
    background-color: #F7F7F7;

    &--buy {
        max-width: $buy-product-modal-width;
        height: unset;
        padding: 0;

        &__actions {
            position: absolute;
            z-index: 1;
            right: 10px;
            top: 10px;

            .close {
                height: auto;
            }
        }

        &__display-image {
            height: 400px;
            width: $buy-product-modal-width;
            object-fit: contain;
        }

        &__product-info {
            padding: 24px;
            display: grid;
            grid-template-columns: 4fr 2fr;
            grid-template-rows: 1fr 3fr;
            height: 300px;
        }

        button {
            height: 60px;
            margin: 0;
            flex-direction: column;
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 10px;
        }

        .share {
            &--inverted {
                flex-direction: row;
                height: 30px;
            }

            img {
                height: 40px;
            }

            p {
                text-align: center;
                margin-bottom: 0;
            }
        }

        &__product-details {
            margin-right: 10px;
        }

        &__delivery-price-section {
            font-size: small;
            padding: 5px 20px;
            border-style: solid;
            border-color: #278982;
            margin: auto;
            margin-top: 0;
            border-radius: 4px;

            &__content {
                display: grid;
                grid-template-columns: 1fr 9fr;
                grid-template-rows: 0fr 0fr;

                svg {
                    display: block;
                    width: 15px;
                    height: auto;
                    margin-top: 1em;
                    margin-inline-end: 0.5em;
                }

                span,
                h3 {
                    color: #5d5d5d;
                    text-align: justify;
                    margin-top: 0.5em;
                    margin-block-end: 0.5em;
                }
            }
        }
    }

    @include screen-below(md) {
        width: 100vw;
        height: 100%;
        padding: 20px;

        &--buy {
            max-width: $buy-product-md-modal-width;
            height: unset;

            &__display-image {
                width: $buy-product-md-modal-width;
            }
        }
    }

    @include screen-below(sm) {
        &--buy {
            max-width: $buy-product-sm-modal-width;
            height: unset;

            &__display-image {
                width: $buy-product-sm-modal-width;
            }
        }

    }

    @include screen-below(xs) {
        &--buy {
            max-width: $buy-product-xs-modal-width;
            height: 650px;

            &__display-image {
                width: $buy-product-xs-modal-width;
            }
        }

    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >img {
            height: 28px;
        }

        >button {
            margin: 0;
            height: 24px;
        }
    }

    &__title {
        display: flex;
        flex-flow: column;
        margin-top: 20px;

        >span {
            font-family: $font-maven-medium;
            font-size: .85rem;
        }

        >i {
            margin: 20px 0;
        }
    }

    >form {
        padding: 0;
    }

    &__form {
        display: grid;
        grid-template-columns: 1fr 2.6fr;
        gap: 35px;
        margin-bottom: 30px;

        @include screen-below(md) {
            grid-template-columns: 1fr;
            gap: 0;
        }

        &__left,
        &__right {
            display: flex;
            flex-flow: column;

            &__input.input-container {
                border-color: #A7A7A7;
            }

            &:last-of-type(.form-field) {
                margin: 0;
            }
        }

        &__left {
            justify-content: space-between;

            &__image {
                position: relative;
                width: 100%;
                aspect-ratio: 1;
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                background-color: $color-primary;
                text-align: center;

                @include screen-below(md) {
                    width: 70%;
                    margin: 0% 15% 10%;
                }

                &:hover {
                    .delete {
                        display: grid;
                    }
                }

                >span {
                    margin: 3px 18px;
                    color: white;

                    &:nth-of-type(2) {
                        font-family: $font-roboto-medium;
                        font-size: 10px;
                        opacity: .5;
                    }
                }

                &__preview {
                    width: 100%;
                    height: 100%;
                }

                .delete {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: none;
                    place-items: center;
                    background-color: rgba(black, .5);
                    cursor: pointer;

                    svg {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }

        &__right {
            &__textarea {
                border: 1px solid #A7A7A7;
            }
        }

    }

    &__display {

        &__title {
            h3 {
                font-size: 32px;
                color: $color-grey-5d5d5d;
                font-family: $font-roboto-black;
                margin: 0;
            }

            span {
                display: flex;
                align-items: center;
                text-decoration: underline;
                height: 24px;

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    margin-right: 5px;
                }
            }
        }
    }

    &__bottom {
        &__actions {
            z-index: 2005;
            top: 50px;
            left: calc(50% + 400px);
            width: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            row-gap: 5px;
        }
    }

    &__delivery {
        &__info {
            color: white;
            font-size: small;
            margin-bottom: 15px;
            padding: 10px;
            background-color: #278982;
            border-radius: 4px;
        }
    }
}
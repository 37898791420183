/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */


$container-item-max-width: 830px;
$container-item-min-width: 200px;
$container-item-min-height: 250px;

.rede-sportrack-screen {
    &__body {
        display: flex;
        padding: 30px 60px;

        @media (max-width: 830px) {
            display: grid;
            grid-template-rows: 1fr auto;
        }
    }

    &__filter {
        position: sticky;
        display: flex;
        height: 90vh;
        width: 300px;
        top: 80px;

        @media (max-width: 1020px) {
            position: static;
            width: 200px;
            height: auto;
        }
    }

    &__bottom-ref {
        padding-bottom: 30px;
    }

    &__users {
        overflow: auto;
        margin-top: 20px;
        width: 100%;

        @include screen-below('md') {
            padding: 0px 10px 30px;
        }

        @include screen-below('sm') {
            padding: 0px 15px 30px;
        }

        @include screen-below('xs') {
            padding: 0px 10px 30px;
        }

        > :first-child {
            width: 100%;
        }

        &--loading {
            display: flex;
            justify-content: center;
            margin: 20px 0px
        }

        &--empty {
            color: black;
            opacity: .5;

            & li {
                padding-left: 10px;
            }
        }

        &__counter {
            font-family: $font-maven-medium;
            font-size: 14px;
            opacity: 0.23;
        }
        
        &__grid {
            display: grid;
            margin-top: 15px;
            grid-gap: 15px;
            grid-template-columns: repeat(auto-fit, $container-item-min-width);
            justify-items: center;

            @media (max-width: $breakpoint-md) {
                justify-content: center;
                grid-template-columns: repeat(auto-fit, 185px);
                grid-gap: 30px;
            }
            
            @media (max-width: 780px) {
                justify-content: center;
                grid-template-columns: repeat(auto-fit, 175px);
                grid-gap: 30px;
            }


            &__item {

                align-items: center;
                display: flex;
                flex-direction: column;
                min-width: $container-item-min-width;
                max-width: 218px;
                min-height: $container-item-min-height;
                max-height: 254px;
                border: 1px solid rgba(0, 0, 0, 0.08);

                > hr {
                    margin-top: 0;
                    border-style: solid;
                    border-width: 4px;
                    width: 100%;
                    opacity: 0.8;

                    &.athlete {
                        background-color: $color-brown-893636;
                        border-color: $color-brown-893636;
                    }

                    &.manager {
                        background-color: $color-yellow-898236;
                        border-color: $color-yellow-898236;
                    }
                    
                    &.sponsor {
                        background-color: $color-blue-366C89;
                        border-color: $color-blue-366C89;
                    }
                }

                &__container {
                    cursor: pointer;
                    width: 100%;
                    height: 100%;

                    &__info {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                        justify-content: space-between;
                        padding: 30px 0px;
                        word-break: break-word;
                        text-align: center;

                        img {
                            width: 40%;
                            border-radius: 50%;
                        }

                        &__sport {

                            &--athlete {
                                color: $color-brown-893636;
                            }

                            &--sponsor {
                                color: $color-blue-366C89;
                            }

                            &--manager {
                                color: $color-yellow-898236;
                            }
                        }
                    }
                }
            }
        }

    }

    &__header {
        display: flex;
        position: sticky;
        z-index: $tabs-z-index;
        top: 50px;
        justify-content: center;
        height: 50px;
        padding: 0px 60px;
        background-color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

        &__search {
            display: flex;
            justify-content: flex-start;
            width: 50%;

            @include screen-below('md') {
                width: 100%;
            }
        }

        &__tabs {
            display: flex;
            width: 100%;

            &--tab {

                display: flex;
                align-items: center;
                position: relative;
                padding: 0 15px;
                cursor: pointer;

                &.all {
                    @include screen-below(sm) {
                        display: none;
                    }
                }

                &::before {
                    content: '';
                    visibility: hidden;
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $color-primary;
                    transform: scaleX(0);
                    transition: all 0.2s ease-in;
                }

                &--active {
                    color: $color-primary;

                    &::before {
                        visibility: visible;
                        transform: scaleX(1);
                    }
                }

            }
        }
    }
}
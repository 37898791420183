/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.user-wallet {
    background-color: $color-white-F7F7F7;
    color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 36px;
    font-size: 14px;
    border-radius: 4px;
    padding: 5px 15px;

    &__credit-amount {
        margin: 0px 5px;
    }
}

.MuiPopover-paper {
    right: 40px;
    padding: 5px 10px;
    width: 390px;
    height: unset;

    span {
        font-size: 14px;
    }
}

.transactions-modal {
    height: 450px;
    overflow-y: auto;

    .take-credit,
    .give-credit {
        margin: 10px;

        .row {
            width: 100%;
            margin: 0px;
            display: grid;
            grid-template-columns: 6fr 4fr;
        }

        .credit-number {
            margin-left: auto;
        }
    }

    .give-credit {
        .created-date,
        .credit-number {
            color: $color-primary;
        }
    }

    .take-credit {
        .created-date,
        .credit-number {
            color: $color-red-EF6262;
        }
    }

    .no-info {
        margin: 15px 10px;

        span {
            color: $color-light-grey;
        }
    }
}

.buy-credits{
    margin: 10px;
    margin-top: 15px;

    a {
        margin: 0px;
    }
    
    &__btn {
        width: 100%;
        margin: 0px;
        padding: 10px;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        background-color: $color-primary;
        color: $color-white-F7F7F7;
        text-decoration: none;
    }
}

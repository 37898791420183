/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

.MuiDrawer-root .MuiPaper-root {
    top: 50px;
}

.MuiDrawer-root .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0);
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: $color-primary-light !important;
}

.filter {
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: 325px;
    top: 100px;

    @include screen-above(md) {
        position: sticky;
        top: 65px;
        height: 637px;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-family: $font-maven-black; 
        width: 100%;
        padding-bottom: 10px;

        @include screen-below(md) {
            display: none;
        }
        
        .title {    
            font-family: $font-maven-black; 
            font-size: 22px;
            color: $color-black-2E2E2E;
        }
    }

    &__body {
        height: 485px;
        overflow-y: auto;
        overflow-x: hidden;

        .MuiFormLabel-root {
            margin: 20px 0 5px 0;
        }

        .MuiAccordion-root:before {
            background-color: transparent;
        }

        .MuiAccordion-root {
            box-shadow: none;
            
            &.Mui-expanded {
                margin: 0;
            }
        }
        
        .MuiAccordionSummary-root {
            padding: 0 16px 0 0;

            &.Mui-expanded {
                min-height: 55px;
            }
        }

        .MuiAccordionDetails-root {
            padding: 0 16px 0 16px;
        }

        @include screen-below(md) {
            display: none;
        }

        &__location {
            .MuiAutocomplete-hasPopupIcon {
                width: 260px;
                margin-bottom: 20px;
            }

            .MuiFormLabel-root {
                margin: 0;
                
                &.Mui-focused{
                    color: $color-light-grey;
                }
            }

            .MuiOutlinedInput-root {
                &.Mui-focused fieldset {
                  border-color: $color-light-grey;
                }
            }
        }

        &__profile, &__roles, &__sports {
            display: flex;
            flex-direction: column;
        }
    }

    &__footer {
        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            padding: 10px; 
            margin: 20px auto;
    
            @include screen-below(md) {
                display: none;
            }
        }
    }

    &__button {
        display: none;

        @include screen-below(md) {
            display: flex;
            margin: 0;
            width: 150px;
        }
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        padding: 25px;
        width: 325px;
        height: 95vh;

        .MuiFormLabel-root {
            margin: 5px 0 5px 0;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $color-primary-light;
        }

        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            cursor: pointer;
        }

        &__header {
            display: flex;
            padding: 25px 0;

            .title {
                font-size: 22px;
                color: $color-black-2E2E2E;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
            
            .MuiPaper-root {
                top: 0;
            }

            .MuiFormLabel-root {
                margin: 20px 0 5px 0;
            }
    
            .MuiCheckbox-colorSecondary.Mui-checked {
                color: $color-primary-light;
            }

            .MuiAccordion-root:before {
                background-color: transparent;
            }

            .MuiAccordion-root {
                box-shadow: none;
                
                &.Mui-expanded {
                    margin: 0;
                }
            }
            
            .MuiAccordionSummary-root {
                padding: 0 16px 0 0;

                &.Mui-expanded {
                    min-height: 55px;
                }
            }

            .MuiAccordionDetails-root {
                padding: 0 16px;
            }

            &__location {
                .MuiAutocomplete-hasPopupIcon {
                    width: 240px;
                    margin-bottom: 20px;
                }
    
                .MuiFormLabel-root {
                    margin: 0;
                    
                    &.Mui-focused{
                        color: $color-light-grey;
                    }
                }
    
                .MuiOutlinedInput-root {
                    &.Mui-focused fieldset {
                      border-color: $color-light-grey;
                    }
                }
            }

            &__profile, &__roles, &__sports {
                display: flex;
                flex-direction: column;
            }   
        }
        
        &__footer {
            padding: 20px 0 80px 0;

            &__btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 150px;
                padding: 10px; 
                margin: 0 auto;
            }
        }
    }
}
/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.registration-screen {
    .register-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;

        p {
            font-family: $font-roboto-medium;
            color: rgba(#303030, 0.6);
        }
    }

    .register-button-container {
        display: flex;
        justify-content: center;
        width: 100%;

        button {
            width: auto;
        }
    }
    .recaptcha {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: white;
        &-error {
            color: $color-error;  
        }
    }
}

/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */


.product-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
    min-width: 230px;
    max-width: 230px;
    padding: 17px;
    box-shadow: 0px 3px 6px #00000029;

    &--inactive {
        background-color: rgba(white, .5);

        > img,
        > span,
        > button,
        .product-card__header__left {
            opacity: .5;
        }
    }

    &--placeholder {
        padding: 19px 17px;

        &__container {
            display: flex;
            flex-flow: column;
            align-items: center;
            background-color: $color-dark-grey;
            padding: 70px 24px 60px;
            text-align: center;
            cursor: pointer;

            &__icon {
                width: 56px;
                height: 56px;
                display: grid;
                place-items: center;
                margin-bottom: 38px;
                border: 2px solid white;
                border-radius: 50%;
                
                .icon-add {

                    &__svg {
                        height: 20px;
                        width: 20px;
                        transform: rotate(45deg);
                    }

                    &__icon {
                        display: inline-flex;
                    }
                }
            }

            &__description {
                display: flex;
                flex-flow: column;
                gap: 30px;

                > span {
                    font-family: $font-roboto-light;
                    font-size: 13px;
                    line-height: 18px;
                    color: white;
                }
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;

        &__left {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            
            > span {
                font-family: $font-roboto-bold;
            }
        }

        &__right {
            position: relative;

            &__image {
                height: 20px;
                color: $color-dark-grey;
                opacity: .9;
            }
        }
    }

    > button {
        display: inline-block;
        margin: 0;
        font-family: $font-maven-medium;
        font-size: .7rem;
        letter-spacing: .1px;
        text-transform: none;

        img {
            width: 196px;
            height: 196px;
        }

        span {
            display: -webkit-box;
            margin: 0;
            font-family: $font-roboto-regular;
            font-size: .8rem;
            line-height: 19px;
            height: calc(19px * 3);
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            /* autoprefixer: off */
            -moz-box-orient: vertical;
            -webkit-box-orient: vertical;
        }

        > strong {
            font-weight: bold;
        }
    }
}
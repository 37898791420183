/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.confirmation-modal {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    width: 100%;

    &__content {
        &__delete-icon {
            width: 100px;
        }

        &__title {
            font-family: $font-maven-medium !important;
            font-size: .9rem !important;
            color: #ffffff !important;
        }

        &__modal-buttons {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            .btn--warning {
                margin-right: 30px;
            }
        }
    }

    @include screen-below(md) {
        img {
            height: auto;
        }

        video {
            height: auto;
            width: 100vw;
        }
    }
}

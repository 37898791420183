/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    .react-datepicker {
        font-family: $font-roboto-regular;

        .react-datepicker__header-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                height: 30px;
                margin: 0 10px;
                padding: 0 10px;
            }

            select {
                font-size: 12px;
                border: none;
                //border-bottom: 1px solid $color-primary;
                border-radius: 0;
                background-color: transparent;
            }
        }

        .react-datepicker__triangle {
            left: 15px;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected {
            background-color: $color-primary;
        }
    }
}

/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.timeline {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;

    &--tooltips-container {
        top: 0;
        z-index: 1000;
    }

    &__controls {
        margin: 0;
        padding: 0 5px;
    }

    &__body {
        position: relative;
        display: flex;
        min-height: 50px;
        width: 100%;
        margin-bottom: 20px;
        background-repeat: repeat-x;
        overflow-x: hidden;
        scroll-behavior: smooth;
        justify-content: space-between;

        @include screen-below('sm') {
            width: calc(100vw - 80px);
        }

        &__entry {
            display: flex;
            flex: 1 0 150px;
            flex-flow: column;
            align-items: center;

            
            &__pin {
                position: relative;
                display: flex;
                flex-flow: column;
                align-items: center;
                $entryPin: &;

                &__text {
                    position: absolute;
                    bottom: 1.3rem;
                    color: white;
                }

                &__delete {
                    position: absolute;
                    bottom: .8rem;
                    visibility: hidden;
                }

                &:hover {
                    opacity: .6;
                    cursor: pointer;

                    #{$entryPin}__text {
                        visibility: hidden;
                    }

                    #{$entryPin}__delete {
                        visibility: visible;
                    }
                }
            }

            &__dot {
                height: .75rem;
                width: .75rem;
                border-radius: 50%;
                background-color: $color-primary;
            }

            &__date {
                font-size: .875rem;
                color: $color-green-001609;
                font-family: $font-maven-regular;
            }
        }

        &__trail {
            position: absolute;
            display: flex;
            gap: 2px;
            width: 100%;
            height: 2px;
            bottom: 22px;

            &__icon {
                min-width: 24px;
                height: 100%;
                background-color: $color-primary;
            }
        }

        &__tooltip {
            display: flex;
            flex-flow: column;
            z-index: 10000;
            background-color: $color-green-001609;
            font-family: $font-maven-regular;
            color: white;
            padding: 10px;
            position: absolute;
            border-radius: 5px;
            transform: translate(-50%, -110%);
            
            > span {
                white-space: nowrap;
            }
        }
    }

    &__add-icon {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .icon-add__icon {
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .icon-add__svg {
            transform: rotate(45deg);
            background-color: $color-light-orange;
            border-radius: 50%;
        }
    }
}
/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

 .credit-edit-modal {
    &__user-details,
    &__result {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 25px;
    }

    &__amount,
    &__amount-negative {
        font-size: 24px;
        margin: 5px;
    }

    &__amount {
        color: $color-primary;
    }

    &__amount-negative {
        color: $color-red-EF6262;
    }

    &__label {
        color: $color-light-grey;
        font-size: 14px;
    }

    &__form {
        .input-container input,
        .react-select__value-container {
            border: none;
            width: 100%;
            background-color: $color-white-F7F7F7;
        }
    }

    &__actions {
        flex-direction: row;
        justify-content: space-between;

        .disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.3;
        }

        .enabled {
            cursor: pointer;
            pointer-events: visible;
            opacity: 1;
        }
    }
}

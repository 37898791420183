/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.about-screen {
    .top-menu {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background-color: #e4e5e4;
        z-index: 10;

        @include screen-below('lg') {
            display: none;
        }
    }

    .about-us {
        display: flex;
        background-color: #e4e5e4;

        > div {
            padding: 65px 30px 25px 30px;

            h2 {
                margin: 0 0 15px 0;
                font-family: $font-aachen-bt-bold;
                font-size: 39px;
                color: #3eb1a8;
                font-weight: lighter;
            }

            p {
                margin: 0 0 20px 0;
                font-family: $font-maven-medium;
                font-size: 16px;
                line-height: 22px;
                color: #2b2d2c;

                strong {
                    font-family: $font-maven-bold;
                }

                @include screen-below('sm') {
                    line-height: 18px;
                }
            }
        }
    }

    .forwho {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;

        @include screen-below('md') {
            flex-wrap: wrap;
        }

        &__item {
            height: 800px;

            @include screen-below('md') {
                width: 100% !important;
            }

            .top {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 500px;
                overflow: hidden;
                background-position: center;
                background-size: cover;
                background-color: black;

                > div {
                    display: inline-flex;
                    padding: 20px 30px;
                    z-index: 1;
                    background-color: rgba(#001608, 0.8);

                    p {
                        margin: 0 0 0 10px;
                        font-family: $font-maven-medium;
                        font-size: 28px;
                        color: #ffffff;
                    }
                }
            }

            .bottom {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                height: 300px;
                padding: 20px 30px;
                overflow: hidden;

                @include screen-below('md') {
                    padding-bottom: 30px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    right: -50px;
                    top: 0;
                    bottom: 0;
                    width: 100px;
                    background-color: #ffffff;
                    transform: skew(-17.5deg);

                    @include screen-below('md') {
                        display: none !important;
                    }
                }

                .details-list {
                    list-style-type: none;
                    padding: 8px 0;

                    li {
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        font-family: $font-maven-regular;
                        margin-bottom: 6px;

                        .icon-check-circle {
                            width: 16px;
                            height: 16px;
                            fill: #ffffff;
                            margin-right: 10px;
                        }
                    }
                }

                h2 {
                    width: 90%;
                    margin: 0;
                    font-family: $font-aachen-bt-bold;
                    font-size: 32px;
                    font-weight: lighter;

                    @include screen-below('md') {
                        margin: 0 0 14px 0;
                    }
                }

                p {
                    flex: 1;
                    width: 80%;
                    font-family: $font-maven-medium;
                    font-size: 18px;
                    color: $color-green-001609;
                }

                button {
                    min-width: 190px;
                    text-transform: uppercase;
                    margin: 0;
                }
            }

            &--athletes {
                width: 33%;

                .top {
                    background-image: url('../../../images/forwho_athletes.png');
                }

                .bottom {
                    background-color: #278982;

                    &::before {
                        background-color: #bddbd9;
                    }

                    h2 {
                        color: #ffffff;
                    }
                }
            }

            &--managers {
                width: 34%;

                .top {
                    background-image: url('../../../images/forwho_managers.png');

                    > img {
                        position: absolute;
                        bottom: -3px;
                        width: 100%;
                    }
                }

                .bottom {
                    background-color: #bddbd9;

                    h2 {
                        color: $color-green-001609;
                        font-weight: lighter;
                    }
                }
            }

            &--sponsors {
                width: 33%;

                .top {
                    background-image: url('../../../images/forwho_sponsors.png');
                }

                .bottom {
                    background-color: #ffffff;

                    .details-list {
                        li {
                            .icon-check-circle {
                                fill: $color-primary;
                            }
                        }
                    }

                    h2 {
                        color: #278982;
                        font-weight: lighter;
                    }
                }
            }
        }
    }

    .sport-management {
        display: flex;
        justify-content: flex-end;
        padding-right: 50px;
        background-image: url('../../../images/sport_management.png');
        background-size: cover;

        @include screen-below('sm') {
            padding: 0;
        }

        .bubble-box {
            @include screen-below('sm') {
                width: auto;
                max-width: 400px;
                margin-left: 60px;
                margin-right: 10px;
            }

            h2 { 
                font-weight: lighter;
            }
        }
    }

    .health-care {
        display: flex;
        justify-content: flex-start;
        padding-left: 50px;
        background-image: url('../../../images/health_care.png');
        background-size: cover;

        @include screen-below('sm') {
            padding: 0;
        }

        .bubble-box {
            @include screen-below('sm') {
                width: auto;
                max-width: 400px;
                margin-left: 10px;
                margin-right: 60px;
            }

            h2 { 
                font-weight: lighter;
            }
        }
    }

    .plans {
        > div {
            padding: 50px 50px 20px 50px;

            @include screen-below('md') {
                padding: 20px;
            }

            p {
                margin: 0 0 10px 0;
                font-family: $font-maven-bold;
                font-size: 16px;
                color: #1c1d1c;
            }

            h2 {
                margin: 0;
                font-family: $font-aachen-bt-bold;
                font-size: 40px;
                font-weight: lighter;
                color: #278982;

                @include screen-below('md') {
                    font-size: 30px;
                }
            }

            h4 {
                margin: 15px 0;
                font-family: $font-maven-bold;
                font-size: 18px;
                color: #066c7d;

                > span {
                    font-family: $font-maven-bold;
                    font-size: 23px;
                    color: #00230e;
                }
            }
        }

        > ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;

            @include screen-below('md') {
                flex-direction: column;
            }

            li {
                position: relative;
                width: 33.33%;
                background-image: url('../../../images/plans_bg_1.svg');
                background-repeat: no-repeat;
                background-position: center top;
                background-size: 101%;

                @include screen-below('md') {
                    width: 100% !important;
                }

                .arrows {
                    position: absolute;
                    top: 70px;
                    left: 40px;
                    width: 35%;
                    max-width: 160px;
                }

                .can {
                    position: absolute;
                    top: 75px;
                    right: 50px;
                    width: 20%;
                    max-width: 105px;
                }

                .plan {
                    padding: 240px 25px 25px 25px;

                    .bottom {
                        border-top: 1px solid rgba(#ffffff, 0.35);

                        @include screen-below('md') {
                            margin: 0;
                        }
                    }
                }

                &.plan2 {
                    width: 33.34%;
                    background-image: url('../../../images/plans_bg_1.svg');
                }

                &.plan3 {
                    background-image: url('../../../images/plans_bg_1.svg');
                }
            }
        }
    }

    .sponsors {
        background-color: #2b2d2c;

        > div {
            display: flex;
            margin-bottom: 20px;

            @include screen-below('md') {
                flex-direction: column;
            }

            > img {
                @include screen-below('md') {
                    order: 1;
                    width: 100%;
                }
            }

            > div {
                flex: 1;
                position: relative;
                margin-bottom: 15px;
                padding: 45px 25px;

                @include screen-below('md') {
                    order: 2;
                    padding: 45px 25px 0 25px;
                }

                h2 {
                    margin: 0;
                    font-family: $font-maven-bold;
                    font-size: 27px;
                    font-weight: lighter;
                    color: #3eb1a8;
                }

                h4 {
                    margin: 10px 0;
                    font-family: $font-aachen-bt-bold;
                    font-size: 37px;
                    color: #ffffff;

                    @include screen-below('md') {
                        font-size: 30px;
                    }
                }

                h5 {
                    margin: 0 0 40px 0;
                    font-family: $font-maven-bold;
                    font-size: 18px;
                    color: #35aca3;

                    span {
                        color: #ffffff;
                    }
                }

                p {
                    width: 80%;
                    margin: 20px 0;
                    font-family: $font-maven-bold;
                    font-size: 16px;
                    color: #ffffff;

                    @include screen-below('md') {
                        width: 100%;
                        font-size: 14px;
                    }

                    span {
                        color: #35aca3;
                    }
                }
            }
        }

        > ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0 0 20px 0;

            @include screen-below('md') {
                flex-wrap: wrap;
                padding: 0;
            }

            .plan {
                padding: 0 25px;
                border-right: 1px solid #3eb1a8;

                @include screen-below('md') {
                    width: 100%;
                    border-right: none;
                    border-bottom: 1px solid #3eb1a8;
                }

                &:last-child {
                    border-right: none;

                    @include screen-below('md') {
                        border-bottom: none;
                    }
                }

                &--standard {
                    h3 {
                        color: #3eb1a8;
                        span {
                            color: #ffffff;
                        }
                    }
                    .bottom {
                        > div {
                            > div {
                                &::before {
                                    background-color: rgba(#3eb1a8, 0.33);
                                }
                            }
                            .btn {
                                background-color: #278982;
                                color: #ffffff;
                            }
                        }
                    }
                }

                &--performance {
                    h3 {
                        color: #6daaa5;
                        span {
                            color: #ffffff;
                        }
                    }
                    .bottom {
                        > div {
                            > div {
                                &::before {
                                    background-color: rgba(#6daaa5, 0.33);
                                }
                            }
                            .btn {
                                background-color: #6daaa5;
                                color: #ffffff;
                            }
                        }
                    }
                }

                &--universal {
                    h3 {
                        color: #a4b2b1;
                        span {
                            color: #ffffff;
                        }
                    }
                    .bottom {
                        > div {
                            > div {
                                &::before {
                                    background-color: rgba(#a4b2b1, 0.33);
                                }
                            }
                            .btn {
                                background-color: #646767;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }

    .institutional-support {
        display: flex;
        background-color: #e4e5e4;

        @include screen-below('md') {
            flex-direction: column;
        }

        > img {
            height: 314px;
            @include screen-below('md') {
                height: auto;
                width: 100%;
            }
        }

        > div {
            flex: 1;
            padding: 25px 30px;

            h3 {
                margin: 0 0 10px 0;
                font-family: $font-maven-regular;
                font-size: 27px;
                color: #2b2d2c;

                @include screen-below('md') {
                    font-size: 18px;
                }
            }

            h2 {
                margin: 0 0 15px 0;
                font-family: $font-aachen-bt-bold;
                font-size: 39px;
                color: #3eb1a8;
                font-weight: lighter;
            }

            p {
                margin: 0 0 20px 0;
                font-family: $font-maven-medium;
                font-size: 16px;
                line-height: 22px;
                color: #2b2d2c;

                @include screen-below('md') {
                    line-height: 18px;
                }

                &.small {
                    margin: 0;
                    font-size: 13px;
                    color: rgba(#4b4b4b, 0.45);
                }
            }

            button {
                margin-bottom: 15px;
                background-color: #6daaa5;
                color: #ffffff;
            }
        }
    }
}

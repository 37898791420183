/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.avatar {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 30px;

    .image {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 30px;
        background-color: #fff;
        border: 2px solid #ffffff;

        img {
            width: 100%;
        }

        &--no-image {
            background-color: $color-primary-dark;
            background-image: url('../../../images/avatar_default.svg');
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .badge {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: $color-primary;
        border: 2px solid #ffffff;
        box-shadow: 0px 5px 5px 0px rgba(#000, 0.2);
    }

    &--small {
        width: 22px;
        height: 22px;
        border-radius: 50%;

        .image {
            width: 100%;
            height: 100%;
            border: 1px solid #ffffff;
            border-radius: 50%;
        }

        .badge {
            width: 10px;
            height: 10px;
        }
    }

    &--cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        background-color: rgba(#000000, 0.5);
        z-index: 1;
        opacity: 0;
    }

    &--editable {
        &:hover {
            cursor: pointer;

            .avatar--cover{
                opacity: 1;

                display: grid;
                place-items: center;
                grid-template-columns: 1fr 1fr;
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.sponsor-card {
    max-width: 670px;
    width: 49vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 auto;

    @include screen-below(sm) {
        width: 100%;
        height: 100vh;
        max-width: unset;
        margin: unset;
        justify-content: unset;
    }


    &__container {
        display: flex;
        flex-flow: column;
        align-items: center;
        height: unset;
        max-width: unset;

        @include screen-below(sm) {
            height: 100%;
        }

        &__top {
            width: 100%;
            padding: 16px 19px 36px 13px;

            &__countdown-slider {
                @keyframes countdown-slider-animation {
                    from {
                        background-position: 100%;
                    }

                    to {
                        background-position: 200%;
                    }
                }

                height: 2px;
                background-image: linear-gradient(to left, $color-primary 50%, #FFF  50%);
                animation: countdown-slider-animation 8s;
                background-size: 200% 100%;
                margin-bottom: 22px;

                @include screen-below(sm) {
                    margin: 0 14px 22px 14px;
                }
            }

            &__header {
                display: flex;
                justify-content: space-between;

                strong {
                    align-self: center;
                    padding-left: 13px;
                    color: $color-primary;
                    font-family: $font-maven-bold;
                }

                img {
                    margin-right: 16px;

                    @include screen-below(sm) {
                        margin-right: 10px;
                    }
                }
            }
        }

        &__content {
            width: 50%;
            flex-grow: unset;
            align-items: center;
            text-align: center;
            overflow: unset;

            @include screen-below(sm) {
                height: unset;
            }

            @include screen-below(xs) {
                width: 100%;
                padding: 0 35px 0 28px;
            }

            &__logo {
                flex-shrink: 0;
                margin-bottom: 20px;
                height: 52px;
            }

            &__description {
                display: inline;
                font-family: $font-maven-regular;
                font-size: .9rem;

                &:first-of-type {
                    margin-top: 10px;
                }

                &--marked {
                    font-family: $font-maven-bold;
                    color: $color-primary;
                }
            }

            &__blisscare {
                width: 210px;
            }
        }

        &__footer {
            display: flex;
            flex-flow: column;
            align-items: center;
            margin-top: 25px;
            background: linear-gradient(180deg, transparent 54px, $color-primary 54px);
            width: 100%;

            @include screen-below(sm) {
                height: 100%;
                justify-content: space-between;
            }

            &__photos-container {
                display: flex;
                gap: 55px;

                @include screen-below(xs) {
                    gap: 8vw;
                }

                &__photo {
                    display: flex;
                    flex-flow: column;

                    img {
                        width: 107px;
                        border-radius: 50%;
                        border: 2px solid #FFF;
                    }

                    @include screen-below(xs) {
                        img {
                            width: 88px;
                        }
                    }

                    &__name {
                        text-align: center;
                        color: white;
                    }

                    &__description {
                        text-align: center;
                        color: white;
                        font-family: $font-maven-regular;
                    }
                }
            }

            &__btn {
                margin: 30px 0 25px 0;

                @include screen-below(sm) {
                    margin-bottom: 80px; // fix for mobile bottom being cut off and not be able to scroll
                }
            }
        }
    }


}

/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

.search-input {
    width: 100%;
    position: relative;

    &--admin {
        .field-error {
            position: absolute;
            margin-top: 5px;
        }

        @media (max-width: $breakpoint-sm) {
            padding-top: 10px;
        }
    }

    .btn-close {
        padding: 0px;
        margin: 0px;
        position: absolute;
        background-color: transparent;
        width: 40px;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &--admin {
            top: 20px;
            right: 0;

            @media (max-width: $breakpoint-sm) {
                top: 30px
            }
        }

        &.active {
            pointer-events: all;
            opacity: 1;
        }

        svg {
            width: 16px;
            height: 16px;

            path {
                fill: $color-lighter-grey;
            }
        }
    }

    .btn-search {
        padding: 0px;
        margin: 0px;
        position: absolute;
        background-color: transparent;
        width: 40px;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        &--admin {
            top: 20px;
            left: 0;

            @media (max-width: $breakpoint-sm) {
                top: 30px
            }
        }

        &.active {
            pointer-events: none;
            opacity: 0;
        }
    }

    .search-input {
        width: 100%;
        background-color: $color-white-F7F7F7;
        height: 40px;
        padding: 0px 15px 0px 42px;
        border: none;
        line-height: 24px;
        font-size: 14px;
        box-shadow: 0 0 4px $color-lighter-grey;
        border-radius: 12px;
        transition: all 0.3s ease-in-out;

        &.active {
            padding: 0px 42px 0px 15px;
        }
    }
}
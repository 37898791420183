/*
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.profile-banner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 35px 0;
    background-color: $color-green-001609;
    text-decoration: none;

    @include screen-below(sm) {
        flex-flow: column;
        padding: 20px 3% 0 3%;
    }

    @include screen-below(xs) {
        padding: 20px 3%;
    }

    &__left-container {
        display: flex;
        flex-flow: column;
        align-items: center;

        @include screen-below(sm) {
            flex-flow: row;
            margin-bottom: 10px;

            img {
                width: 200%;
            }
        }

        img:nth-child(2) {
            width: 85%;
            margin: 12px 0;

            @include screen-below(sm) {
                margin: 0 24px;
                width: 170%;
            }
        }
    }

    &__middle-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include screen-below(sm) {
            flex-flow: column;
            align-items: flex-start;
        }

        &__left {
            height: 100%;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            padding: 20px 0 20px 30px;

            @include screen-below(sm) {
                padding: 0;
            }

            @include screen-below(xs) {
                padding-bottom: 20px;
            }

            &__title {
                font-family: $font-montserrat-italic;
                font-size: 2rem;
                color: white;
            }
            
            &__description {
                margin-left: 10px;
                font-family: $font-maven-regular;
                font-size: 1.1rem;
                color: $color-primary;
            }
        }

        &__right {
            display: flex;

            @include screen-below(sm) {
                width: 100%;
                justify-content: space-between;
            }

            &__logo {
                margin-right: 40px;
            }

            &__arrows {
                @include screen-below(xs) {
                    display: none;
                }
            }

            &__running {
                position: absolute;
                height: 108px;
                bottom: 0;

                @include screen-below(xs) {
                    display: none;
                }
            }
        }
    }

    &__cut-effect {
        position: absolute;
        background-color: rgba(white, .1);
        height: 100%;
        width: 30%;
        right: 0;
        top: 0;
        z-index: 1;
        clip-path: polygon(100% -20%, 0 100%, 100% 100%);

        @include screen-below(md) {
            width: 40%;
        }
    }
}

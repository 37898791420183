/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

$breakpoints: (
    xs: $breakpoint-xs,
    sm: $breakpoint-sm,
    md: $breakpoint-md,
    lg: $breakpoint-lg,
);

@mixin screen-above($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @media (min-width: $breakpoint-value) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin screen-below($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin screen-between($lower, $upper) {
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }
    } @else {
        @if (map-has-key($breakpoints, $lower) == false) {
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }
        @if (map-has-key($breakpoints, $upper) == false) {
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}

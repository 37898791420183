/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

$drodpdown-height: 22px;

.react-select-container {
    width: 100%;
    height: $drodpdown-height;

    .react-select {
        &__input {
            width: 100px !important;
        }

        &__placeholder {
            color: #bfbfbf;
        }

        &__control {
            min-height: 22px;
            height: $drodpdown-height;
            background-color: #ffffff;
            border: none;

            .theme-dark & {
                background-color: #000000;
            }

            .test-screen & {
                background-color: transparent;
            }

            &--is-focused {
                box-shadow: none;
            }
        }

        &__value-container {
            height: $drodpdown-height;
            padding-left: 0;
            font-family: $font-roboto-regular;
            font-size: 15px;

            .react-select {
                &__single-value {
                    top: 60%;
                    margin-left: 1px;
                    margin-right: 1px;
                    color: #000000;

                    .theme-dark & {
                        color: #ffffff;
                    }
                }
            }
        }

        &__indicator-separator {
            display: none;
        }

        &__dropdown-indicator {
            display: flex;
            align-items: center;
            margin: 0;

            svg {
                path {
                    fill: #a0a0a0;
                }
            }

            &--hidden {
                display: none;
            }
        }

        &__menu {
            margin: -2px 0 0 0;
            border: 1px solid #d0d0d0;
            border-radius: 0;
            box-shadow: none;

            .theme-dark & {
                background-color: #000000;
            }
        }

        &__menu-list {
            padding: 0;
        }

        &__option {
            padding: 8px 10px;
            font-family: $font-roboto-regular;
            font-size: 15px;
            color: #000000;

            .theme-dark & {
                color: #bfbfbf;
            }

            &--is-focused {
                background-color: rgba(#d0d0d0, 0.25);
            }

            &--is-selected {
                background-color: rgba(#d0d0d0, 0.75);
            }
        }
    }
}

/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

.athlete-supporter-modal {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 600px;
        height: 600px;
        padding: 24px;
        background-color: #fff;
        background-image: url('../../../images/background_supporter.png');
        background-repeat: no-repeat;
        position: relative;

        .lottie {
            position: absolute;
            top: 0;
            z-index: 5;
            pointer-events: none;
        }

        &--fit {
            height: fit-content;
        }

        &__close-icon {
            width: 100%;
            margin: 0;
            display: flex;
            justify-content: flex-end;

            &:disabled {
                cursor: default;
            }
        }

        &__content {
            padding: 0 50px;
            height: 100%;

            h2 {
                font-size: 24px;
                font-family: $font-maven-regular;
                color: $color-primary-dark;
                margin: 0;
            }

            p {
                font-family: $font-maven-regular;
                font-size: 14px;
                color: $color-green-001609;
                display: inline-flex;
                align-items: center;

                svg {
                    width: 110px;
                    padding-left: 10px;
                }
            }

            &__supporting-request {
                display: inline-flex;
                align-items: center;
                gap: 8px;
                margin-top: 50px;

                .handshake-icon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    background-color: #FFF;
                    margin: 0 -20px;
                    box-shadow: -4px 0px 64px rgba(0, 0, 0, 0.5);
                    z-index: 1;
                }

                .supporter-icon {
                    box-shadow: -4px 0px 64px rgba(148, 77, 199, 0.7);
                    border-radius: 50%;
                }

                .athlete-icon {
                    box-shadow: 4px 0 64px #278982;
                    border-radius: 50%;
                }

                .avatar {
                    $avatar-size: 112px;
                    width: $avatar-size;
                    height: $avatar-size;

                    .image {
                        border: none;
                        border-radius: 50%;
                        width: $avatar-size;
                        height: $avatar-size;
                    }
                }
            }

            &__support-athlete {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                margin-top: 50px;

                > * {
                    margin: 0;
                }

                p {
                    color: $color-black-2E2E2E;
                }

                p, h4 {
                    font-family: $font-maven-regular;
                    font-size: 16px;
                }

                h3, h4 {
                    color: $color-primary;
                }

                h3 {
                    font-family: $font-maven-black;
                    font-size: 16px;
                }
            }

            &__buttons-container {
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
                gap: 16px;

                button {
                    width: 100%;
                    border: 1px solid $color-purple;

                    &.MuiButtonBase-root {
                        font-family: $font-maven-medium;
                        font-size: 14px;
                        text-transform: none;
                    }

                    &.accept {
                        background-color: $color-purple;
                        color: #fff;
                    }

                    &.reject {
                        background-color: #fff;
                        color: $color-purple;
                    }
                }
            }
        }

        @include screen-below(sm) {
            width: 100vw;
            height: 100vh;
        }
    }
}

/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.gallery-drawer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px 20px;
    background-color: $color-green-001609;

    @include screen-below(lg) {
        padding: 40px 20px;
    }

    &__top {
        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            align-items: center;
        }

        .icon-add {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            &__icon {
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__svg {
                transform: rotate(45deg);
                background-color: $color-light-orange;
                border-radius: 50%;
            }
        }

        .title {
            flex: 1;
        }

        .buttons {
            display: flex;
            align-items: center;
            flex: 0;

            button {
                margin: 0 0 0 10px;
                font-family: $font-maven-regular;
                font-size: 14px;
                color: #ffffff;
                opacity: 0.35;

                &.active {
                    font-family: $font-maven-medium;
                    opacity: 1;
                }
            }
        }
    }

    &__content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;

        p {
            font-family: $font-maven-regular;
            font-size: 14px;
            color: #ffffff;
            opacity: 0.35;
            text-align: center;
        }

        &__image {
            position: relative;
            width: 46%;
            height: 110px;
            margin-bottom: 20px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
            box-sizing: border-box;
            border: $color-primary-dark 1px solid;

            &--loader {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: default;

                svg {
                    fill: $color-primary;
                }
            }

            @include screen-below(lg) {
                height: 23vw;
            }

            img {
                position: absolute;
                right: 5px;
                top: 5px;
                display: none;
            }

            &:hover {
                img {
                    display: block;
                }
            }
        }
    }

    .gallery-modal {
        align-items: center;
        display: flex;
        height: 100vh;
        justify-content: center;
        text-align: center;
        overflow: hidden;
        color: #ffffff;

        .delete-icon {
            width: 100px;
        }

        .modal-buttons {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            .btn--warning {
                margin-right: 30px;
            }
        }

        .content {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &__close-btn {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 28px;
                height: 28px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(#000, 0.6);
                cursor: pointer;
                z-index: 2000;

                img {
                    width: 25px;
                    height: 25px;
                }
            }

            > .share {
                position: absolute;
                top: 10px;
                right: 43px;
                height: 28px;
                padding: 0 12px 0 0;
                background-color: rgba($color-green-001609, .7);
                z-index: 2000;
                flex-direction: row;
                justify-content: center;

                > img {
                    height: auto;
                    max-width: 100vw;
                }

                > p {
                    margin: 0;
                    color: $color-primary;
                }
            }

            img, video {
                max-height: 100vh;
                max-width: 100%;
            }

            @include screen-below(md) {
                img {
                    height: auto;
                    max-width: 100vw;
                }

                video {
                    height: auto;
                    max-width: 100vw;
                }
            }
        }
    }
}

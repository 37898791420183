/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

 .contact-modal {
    min-width: 40vw;

    &__form {
        .input-container textarea {
            overflow-y: auto;
            border: 1px solid $color-grey-A7A7A7;
        }
    }
}
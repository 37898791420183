/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.validate-account-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #1b3230;

    > button {
        position: absolute;
        top: 10px;
        right: 10px;

        svg {
            path {
                fill: #fff;
            }
        }
    }

    > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
            margin: 0 0 20px 0;
        }

        h1 {
            margin: 0 0 0 30px;
            font-family: $font-aachen-bt-bold;
            font-size: 30px;
            color: $color-error;
        }

        p {
            margin: 0 0 20px 30px;
            font-family: $font-maven-regular;
            font-size: 16px;
            color: #abb3b2;
        }

        form {
            width: 100%;

            .form-field {
                width: 100%;
                margin: 0 0 40px 30px;

                .input-container {
                    input {
                        color: #ffffff;
                    }
                }
            }

            button {
                margin: 0 0 0 30px;
            }
        }
    }
}

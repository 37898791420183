/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.admin-screen {
    &__header {
        display: flex;
        justify-content: space-between;
        padding: 50px 50px 0 50px;

        @include screen-below(sm) {
            padding: 25px 25px 0 25px;
        }

        .btn {
            margin-top: 6px;
            padding: 0 7px;
        }

        .title {
            font-size: 20px;
        }
    }

    &__content {
        padding: 50px;
        display: flex;
        
        @include screen-below(md) {
            display: flex;
            flex-direction: column;
        }

        @include screen-below(sm) {
            padding: 25px;
        }

        &__body {
            width: 100%;

            @include screen-below(md) {
                overflow: auto;
            }
            
            @include screen-below(sm) {
                padding-top: 20px;
            }

            &__table-header {
                display: flex;
                justify-content: space-between;
                width: 100%;   

                &__title {
                    color: #182b20;
                }
    
                &__search {
                    display: flex;
                    align-content: center;
                    flex-wrap: wrap;
                }
            }
    
            table {
                width: 100%;
                text-align: left;
                padding-top: 20px;
    
                tr {
                    box-shadow: 0px 2px 1px -1px #cccccc;
                }
    
                th {
                    color: $color-primary;
                    padding-bottom: 10px;
    
                    button {
                        display: table-cell;
                        vertical-align: middle;
                        margin: 0;
                        color: $color-primary;
                        font-family: $font-maven-medium;
                        font-weight: bold;
                        font-size: 1rem;
                    }
                }
    
                td {
                    padding: 10px 0;
    
                    img {
                        width: 24px;
                        margin-right: 5px;
                        border-radius: 50%;
                        cursor: pointer;
                    }
    
                    svg {
                        cursor: pointer;
                    }
    
                    div {
                        display: flex;
                        align-items: center;
                    }
                }
    
                .table-action {
                    padding-left: 10px;
                    display: flex;
                }
    
                .icon-credit-card__icon {
                    margin-left: 5px;
                }
    
                .clickable {
                    cursor: pointer;
                }

                .no-data {
                    text-align: center;
                    padding: 20px 0;
                    font-size: 1rem;
                }
            }
    
            .table-pagination {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-top: 20px;
                font-family: $font-roboto-regular;
                color: #aaaaaa;
    
                &__container {
                    display: flex;
                    grid-gap: 20px;
    
                    .form-field {
                        width: 50px;
                    }
                }
            }
        }
    }
}
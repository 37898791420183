/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;

$font-maven-black: 'MavenPro-Black', sans-serif;
$font-maven-bold: 'MavenPro-Bold', sans-serif;
$font-maven-medium: 'MavenPro-Medium', sans-serif;
$font-maven-regular: 'MavenPro-Regular', sans-serif;
$font-aachen-bold: 'AachenBold', sans-serif;
$font-aachen-bt-bold: 'AachenBT-Bold', sans-serif;
$font-roboto-black: 'robotoblack', sans-serif;
$font-roboto-bold: 'robotobold', sans-serif;
$font-roboto-light: 'robotolight', sans-serif;
$font-roboto-medium: 'robotomedium', sans-serif;
$font-roboto-regular: 'robotoregular', sans-serif;
$font-roboto-thin: 'robotothin', sans-serif;
$font-montserrat-italic: 'montserratitalic', sans-serif;

$zindex-loader: 3000;
$zindex-options: 1999;
$zindex-loader-svg: 1;

$top-bar-height: 37px;
$right-drawer-width: 385px;
$buy-product-modal-width: 800px;
$buy-product-md-modal-width: 650px;
$buy-product-sm-modal-width: 400px;
$buy-product-xs-modal-width: 300px;


$color-error: #d86969;
$color-primary: #278982;
$color-primary-dark: #182b20;
$color-primary-light: #3EB1A8;
$color-white-F7F7F7: #F7F7F7;
$color-grey-333333: #333333;
$color-grey-5d5d5d: #5d5d5d;
$color-grey-A7A7A7: #A7A7A7;
$color-red-EF6262: #ef6262;
$color-red-3C0303: #3C0303;
$color-green-001609: #001609;
$color-light-orange: #ffaa61;
$color-brown-893636: #893636;
$color-blue-366C89: #366c89;
$color-dark-grey: #393939;
$color-light-grey: #707070;
$color-lighter-grey: #A8A8A8;
$color-dark-green: #000B04;
$color-pale-gold: #B1A971;
$color-gold: #BC953F;
$color-diamond: #36D4E8;
$color-purple: #944dc7;
$color-grey-aaaaaa: #aaaaaa;
$color-pink-E4BFFF: #E4BFFF;
$color-black-2E2E2E: #2E2E2E;
$color-yellow-898236: #898236;

$tabs-z-index: 99;
$nav-z-index: 100;
$modal-button-z-index: 101;
$modal-select-z-index: 102;